import React from "react";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import Constants from "expo-constants";
import { t } from "react-native-tailwindcss";
import Unorderedlist from "react-native-unordered-list";
import { MediumText } from "../../../atoms/MediumText";
import { LinkText } from "../../../atoms/LinkText";

export const SiporaTerms = (): React.ReactElement => {
  const navigation = useNavigation<NavigationProp<any>>();

  return (
    <>
      <MediumText>
        {Constants.manifest?.extra?.company ?? "Application"} (powered by Sipora
        Pty Ltd) Terms of Use set out below (the Agreement) govern your use of
        the website (Website) or as a mobile phone app (App) and form a binding
        agreement between Sipora Pty Ltd, and you in relation to your use of the
        Website/App.
      </MediumText>
      <MediumText>
        Please read the Agreement carefully before using the Website or App. If
        you have any questions, please{" "}
        <LinkText onPress={() => navigation.navigate("Help")}>
          contact us.
        </LinkText>
      </MediumText>
      <MediumText>
        By using the Website or App, you acknowledge and agree that you have had
        an opportunity to read and understand all of this Agreement and the{" "}
        <LinkText onPress={() => navigation.navigate("PrivacyPolicy")}>
          Privacy Policy
        </LinkText>{" "}
        and you agree to be bound by their terms and conditions.
      </MediumText>
      <MediumText>
        If you do not agree with the terms set out in this Agreement or the
        Privacy Policy, you should not access or use the Website/App.
      </MediumText>
      <MediumText>
        This Agreement does not constitute an agreement by Sipora Pty Ltd to
        provide services to you. Such an agreement with you is only formed when
        your Sipora Pty Ltd App account is established in accordance with the
        Product Disclosure Statement. At that point in time, you will become
        subject to the terms of this Agreement and the terms of the Product
        Disclosure Statement with respect to your use of the Sipora Pty Ltd
        services, and your Sipora Pty Ltd Account. Any services that you receive
        from Sipora Pty Ltd will be subject to the terms of a separate written
        agreement and the applicable Product Disclosure Statement. Sipora Pty
        Ltd will only provide its services to users who meet its eligibility
        requirements which, at this time, include a requirement that the users
        be an Australian resident. Please be aware that whilst Sipora Pty Ltd
        will not be bound to provide you with services prior to the
        establishment of your Sipora Pty Ltd Account in accordance with the
        Product Disclosure Statement, your use of the Website/App prior to that
        point (eg any browsing activity of the publicly accessible portions of
        the Website/App) will remain subject to this Agreement.
      </MediumText>
      <MediumText>
        Sipora Pty Ltd may, from time to time, amend this Agreement. Any such
        amendments will be posted on the Website and/or App and will take effect
        at least fourteen (14) days after such posting. If you do not accept
        such amendments to the Agreement, you cannot continue to use the Website
        or App. By using the Website or App, you agree that the then current
        version of this Agreement (including any amendments effective at that
        time) applies to your use of the Website or App.
      </MediumText>
      <MediumText style={t.fontBold}>General Advice Only</MediumText>
      <MediumText>
        While all reasonable efforts have been made to ensure that the
        information and content contained in this Website/App is accurate and up
        to date, it is not designed to provide personal financial or investment
        advice. The information provided does not take into account your
        particular, financial situation or shopping needs. You consent that the
        product disclosure statement can be sent to you via email.
      </MediumText>

      {/* list */}
      <Unorderedlist>
        <MediumText style={t.fontBold}>General Advice Only</MediumText>

        {/* item 1 */}
        <Unorderedlist>
          <MediumText>
            Sipora Pty Ltd reserves the right to modify, discontinue or disable
            the Website/App or any part of the Website/App (on a permanent or
            temporary basis) at any time. While Sipora Pty Ltd will, where
            reasonably practicable and possible, endeavour to provide you with
            prior notice of such modifications, discontinuations or disabling,
            you agree and accept that it may not always be possible to provide
            you with such prior notification.
          </MediumText>
        </Unorderedlist>

        {/* item 2 */}
        <Unorderedlist>
          <MediumText>
            This page has been prepared by Sipora Pty Ltd for general
            promotional purposes only and is not an offer to sell or
            solicitation to buy any financial product. This page has been
            prepared without taking account of your objectives, financial
            situation or needs. Consequently, before acting on the information
            in this page, you should consider the appropriateness of the
            information in view of your own objectives, financial situation and
            needs.
          </MediumText>
        </Unorderedlist>

        {/* item 3 */}
        <Unorderedlist>
          <MediumText>
            Sipora Pty Ltd makes no representation and gives no advice in
            respect of any financial, investment, tax, legal or accounting
            matters in any jurisdiction including the suitability of any
            financial product to investors. Neither Sipora Pty Ltd nor any of
            its affiliated companies, agents or subcontractors shall be liable
            for any direct, indirect, special, incidental, consequential,
            punitive, or exemplary damages, including lost profits arising in
            any way from, including but not limited to: (i) the information
            provided in the Website/App; (ii) the modification or misuse of
            information on the Website/App; or (iii) claims of third parties in
            connection with the use of this Website/App.
          </MediumText>
        </Unorderedlist>

        {/* item 4 */}
        {/* list */}
        <Unorderedlist>
          <MediumText>You must not use the Website or App:</MediumText>

          {/* item 1 */}
          <Unorderedlist>
            <MediumText>
              a. to collect any personal information on other individuals or to
              upload personal information of other individuals, including their
              names, address, phone number or any other identifying information;
              or
            </MediumText>
          </Unorderedlist>

          {/* item 2 */}
          <Unorderedlist>
            <MediumText>b. for any unlawful purpose.</MediumText>
          </Unorderedlist>
        </Unorderedlist>

        {/* item 5 */}
        <Unorderedlist>
          <MediumText>
            You must not violate or attempt to violate the security of the
            Website or App. You must not hack into the Website, App, Sipora Pty
            Ltd computer systems or the computer systems of other users of the
            Website or App. "Hacking" means unauthorised access, malicious
            damage and/or interference and includes, without limitation,
            spamming, propagating viruses, worms or other types of malicious
            programs, deliberate attempts to overload a computer system,
            broadcast attacks or any other method designed to damage or
            interfere with the operation of a computer system or website/app.
          </MediumText>
        </Unorderedlist>

        {/* item 6 */}
        <Unorderedlist>
          <MediumText>
            If you breach the terms of this Agreement, Sipora Pty Ltd may (at
            its option, and without limiting the remedies available to Sipora
            Pty Ltd in any way) terminate its agreement with you or bar you from
            accessing the Website or App on a temporary or permanent basis.
          </MediumText>
        </Unorderedlist>
      </Unorderedlist>

      {/* list */}
      <Unorderedlist>
        <MediumText style={t.fontBold}>Usernames and passwords</MediumText>

        {/* item 1 */}
        <Unorderedlist>
          <MediumText>
            You can play an important role in keeping your personal information
            secure by maintaining the confidentiality of any password and
            accounts used on the Website or App. Please notify Sipora Pty Ltd
            immediately if there is any unauthorised use of your account by any
            other internet user or any other breach of security
          </MediumText>
        </Unorderedlist>

        {/* item 2 */}
        <Unorderedlist>
          <MediumText>
            You are responsible for the security of your user name and password
            (or other log-in information) and you take responsibility to ensure
            the confidentiality of this information.
          </MediumText>
        </Unorderedlist>
      </Unorderedlist>

      {/* list */}
      <Unorderedlist>
        <MediumText style={t.fontBold}>Intellectual Property Rights</MediumText>

        {/* item 1 */}
        <Unorderedlist>
          <MediumText>
            The Website/App and all content contained on the Website/App
            (including without limitation all text, graphics, icons,
            advertisements, photographs, databases, trade marks and other
            information contained on the Website/App (other than Third Party
            Content) (Sipora Pty Ltd Content) (and all intellectual property
            rights that subsist in the Website/App and Sipora Pty Ltd Content,
            including without limitation copyright, trade marks, patents, design
            rights and all other forms of intellectual property existing in the
            world) are owned and operated by Sipora Pty Ltd or its third party
            licensors and suppliers. Nothing in this Agreement constitutes a
            transfer of any intellectual property rights.
          </MediumText>
        </Unorderedlist>

        {/* item 2 */}
        <Unorderedlist>
          <MediumText>
            Subject to this Agreement and all applicable laws and regulations,
            Sipora Pty Ltd grants you a non-exclusive, non-transferable,
            personal, limited license to download, access, view, use and display
            the Raiz Content which Raiz makes available to you from time to time
            on the terms and conditions set out in this Agreement.
          </MediumText>
        </Unorderedlist>

        {/* item 3 */}
        <Unorderedlist>
          <MediumText>
            You must not do anything which breaches or otherwise interferes with
            Sipora Pty Ltd intellectual property rights or the intellectual
            property rights of any of Sipora Pty Ltd third party licensors. You
            may not distribute, reproduce, publish, alter, modify or create
            derivative works from any Sipora Pty Ltd Content without the prior
            written permission of Sipora Pty Ltd or the relevant third party
            licensor or exploit such contents for commercial benefit.
          </MediumText>
        </Unorderedlist>

        {/* item 4 */}
        <Unorderedlist>
          <MediumText>
            All rights not expressly granted are reserved by Sipora Pty Ltd.
          </MediumText>
        </Unorderedlist>
      </Unorderedlist>

      {/* list */}
      <Unorderedlist>
        <MediumText style={t.fontBold}>Privacy</MediumText>

        {/* item 1 */}
        <Unorderedlist>
          <MediumText>
            By using the Website and the App, you agree to provide true,
            accurate, current and complete information about yourself, and your
            accounts maintained at third party websites and you agree to not
            misrepresent your identity or your account information held with
            third party account providers.
          </MediumText>
        </Unorderedlist>

        {/* item 2 */}
        <Unorderedlist>
          <MediumText>
            By using, and continuing to use, the Website and the App, you
            authorise Sipora Pty Ltd and its service providers (Service
            Providers) to access third party websites designated by you, on your
            behalf, to retrieve information requested by you in order to
            facilitate the provision of services by Sipora Pty Ltd. For that
            sole purpose only, you hereby appoint Sipora Pty Ltd and each
            Service Provider as your agent, with full power of substitution and
            re-substitution, to access third party websites, servers or
            documents, retrieve information, and use your information, all as
            described in this clause, with the full authority to do and perform
            anything necessary to be done in connection with such activities, as
            fully to all intents and purposes as you may or could do in person.
            YOU ACKNOWLEDGE AND AGREE THAT WHEN Sipora Pty Ltd OR A SERVICE
            PROVIDER ACCESSES AND RETRIEVES INFORMATION FROM THIRD PARTY
            WEBSITES, Sipora Pty Ltd AND ANY SERVICE PROVIDER ARE ACTING AS YOUR
            AGENT. You agree that third party account providers are entitled to
            rely on the foregoing authorization and agency granted by you solely
            for the purpose set out in this clause.
          </MediumText>
        </Unorderedlist>

        {/* item 3 */}
        <Unorderedlist>
          <MediumText>
            Any personal information collected by Sipora Pty Ltd through the
            Website/App, or otherwise collected by or on behalf of Sipora Pty
            Ltd, will be dealt with in accordance with the Sipora Pty Ltd
            Privacy Policy.
          </MediumText>
        </Unorderedlist>
      </Unorderedlist>

      {/* list */}
      <Unorderedlist>
        <MediumText style={t.fontBold}>Third Party Activities</MediumText>

        {/* item 1 */}
        <Unorderedlist>
          <MediumText>
            The Sipora Pty Ltd Website or App may contain links to third party
            websites where content is not controlled by Sipora Pty Ltd (Linked
            Sites).
          </MediumText>
        </Unorderedlist>

        {/* item 2 */}
        <Unorderedlist>
          <MediumText>
            Links to Linked Sites are provided for convenience only. The
            appearance of a link to a Linked Site does not imply Sipora Pty Ltd
            endorsement of that Linked Site or any content created or uploaded
            by a party other than Sipora Pty Ltd, including all text, graphics,
            icons, advertisements, photographs, databases, trade marks and other
            information created and/or uploaded by such a party (Third Party
            Content). Sipora Pty Ltd has no control over and is not responsible
            for any Linked Site or any Third Party Content.
          </MediumText>
        </Unorderedlist>

        {/* item 3 */}
        <Unorderedlist>
          <MediumText>
            You access Linked Sites at your own risk and, to the full extent
            permitted by law, subject to clause .6.5, Sipora Pty Ltd disclaims
            all guarantees and warranties, express and implied, as to the
            accuracy, value, legality or otherwise of any materials or
            information contained on such Linked Sites and in Third Party
            Content.
          </MediumText>
        </Unorderedlist>

        {/* item 4 */}
        <Unorderedlist>
          <MediumText>
            Your use of Third Party Content (including, without limitation, that
            featured on Linked Websites) may be subject to a third party's terms
            and conditions of use. It is your responsibility to check and comply
            with such terms.
          </MediumText>
        </Unorderedlist>
      </Unorderedlist>

      {/* list */}
      <Unorderedlist>
        <MediumText style={t.fontBold}>Liability</MediumText>

        {/* item 1 */}
        <Unorderedlist>
          <MediumText>
            To the full extent permitted by law and subject to clause .6.5,
            Sipora Pty Ltd excludes all representations, warranties, guarantees,
            terms and conditions, whether express or implied (and including,
            without limitation, those implied by statute, custom, law or
            otherwise), except as expressly set out in this Agreement.
          </MediumText>
        </Unorderedlist>

        {/* item 2 */}
        <Unorderedlist>
          <MediumText>
            To the full extent permitted by law and subject to clause .6.5, the
            Website/App and the Sipora Pty Ltd Content is provided to you "as
            is" and on an "as available" basis, without any representation or
            endorsement made and without warranty or guarantee of any kind
            (whether express or implied). This means that Sipora Pty Ltd does
            not guarantee continuous, uninterrupted or secure access to the
            Website/App or that its servers are free of computer viruses, bugs
            or other harmful components or that defects will be corrected. As a
            result, you may at times experience disruption or other difficulties
            in using the Website/App. You should consider the potential for
            disruption or other difficulties in planning your use of the
            Website/App. To the extent permitted by law, Sipora Pty Ltd has no
            liability to you whatsoever for loss or costs of any kind you suffer
            as a result of or in connection with any disruption or other
            difficulties in using the Sipora Pty Ltd Website or App.
          </MediumText>
        </Unorderedlist>

        {/* item 3 */}
        <Unorderedlist>
          <MediumText>
            To the full extent permitted by law, subject to clause .6.5 and
            except where the relevant liability arises from Sipora Pty Ltd
            unlawful actions, neither Sipora Pty Ltd nor any party involved in
            creating, producing or delivering the Website/App accepts any
            liability for the accuracy, timeliness or completeness of the
            information contained on the Raiz Website/App or any responsibility
            for any errors or omissions in the content on the Sipora Pty Ltd
            Website/App.
          </MediumText>
        </Unorderedlist>

        {/* item 4 */}
        {/* list */}
        <Unorderedlist>
          <MediumText>
            To the full extent permitted by law, subject to clause .6.5 and
            except where the relevant liability arises from Sipora Pty Ltd
            unlawful actions, Sipora Pty Ltd excludes all liability to you for
            any damages or loss (including without limitation direct,
            incidental, indirect, consequential, special, punitive or exemplary
            damages or any loss of business, interest, goodwill, revenue,
            profit, or loss or corruption of data) arising out of your access
            to, or use of, or inability to use or access the Website/App or
            otherwise in connection with:
          </MediumText>

          {/* item 1 */}
          <Unorderedlist>
            <MediumText>
              a. your use of, or reliance on, the Website/App (or any of the
              Sipora Pty Ltd Content, Linked Site or Third Party Content)
              however caused (including, without limitation, damage or loss
              arising in contract, tort (including, without limitation,
              negligence), statute or otherwise));
            </MediumText>
          </Unorderedlist>

          {/* item 2 */}
          <Unorderedlist>
            <MediumText>
              b. the accuracy, timeliness, credibility, quality, utility or
              completeness of the information contained on the Sipora Pty Ltd
              Website/App; and/or
            </MediumText>
          </Unorderedlist>

          {/* item 3 */}
          <Unorderedlist>
            <MediumText>
              c. any errors or omissions in the content on the Website/App,
              whether or not Sipora Pty Ltd is aware of such errors or
              omissions.
            </MediumText>
          </Unorderedlist>
        </Unorderedlist>

        {/* item 5 */}
        <Unorderedlist>
          <MediumText>
            Certain legislation including the Australian Consumer Law, may imply
            warranties or conditions or impose obligations, remedies or
            guarantees which cannot be excluded, restricted or modified except
            to a limited extent. Such legislation may, for example, impose non-
            excludable guarantees that goods are of acceptable quality or that
            services will be rendered with due care and skill (with certain
            remedies available to consumers for certain breaches of such
            guarantees). To the extent that such legislation applies, this
            Agreement must be read subject to those statutory provisions. If
            those statutory provisions apply, notwithstanding any other
            provision of this Agreement, to the extent that Sipora Pty Ltd is
            entitled to do so, Sipora Pty Ltd limits its liability pursuant to
            such provisions for any goods to the replacement, repair or refund
            of the cost of goods, or for services, to the re-supply or the
            payment of the cost of having the services supplied again.
          </MediumText>
        </Unorderedlist>

        {/* item 6 */}
        {/* list */}
        <Unorderedlist>
          <MediumText>
            You agree to fully compensate Sipora Pty Ltd and its officers,
            employees and agents from and against reasonable liability, loss,
            damage, costs and expense (including, without limitation, reasonable
            legal expenses on a reasonable basis) and penalties incurred or
            suffered by any of them arising out of:
          </MediumText>

          {/* item 1 */}
          <Unorderedlist>
            <MediumText>
              a. your material breach of this Agreement (noting that for the
              purposes of this clause 6.6, a material breach will include,
              without limitation, a breach of a representation, obligation or
              warranty);
            </MediumText>
          </Unorderedlist>

          {/* item 2 */}
          <Unorderedlist>
            <MediumText>
              b. any misuse or use of Sipora Pty Ltd Content in breach of this
              Agreement; and
            </MediumText>
          </Unorderedlist>

          {/* item 3 */}
          <Unorderedlist>
            <MediumText>
              c. any act of fraud or willful misconduct by or on behalf of you.
            </MediumText>
          </Unorderedlist>
        </Unorderedlist>
      </Unorderedlist>

      {/* list */}
      <Unorderedlist>
        <MediumText style={t.fontBold}>General</MediumText>

        {/* item 1 */}
        <Unorderedlist>
          <MediumText>
            We may provide you with notices in connection with this Agreement by
            posting such notices on the Sipora Pty Ltd Website/App or, where
            appropriate, by email or mail if you have provided contact details
            for that purpose.
          </MediumText>
        </Unorderedlist>

        {/* item 2 */}
        <Unorderedlist>
          <MediumText>
            Sipora Pty Ltd may assign this Agreement provided that the relevant
            assignee undertakes to perform all of Sipora Pty Ltd obligations
            herein.
          </MediumText>
        </Unorderedlist>

        {/* item 3 */}
        <Unorderedlist>
          <MediumText>
            The obligations to compensate for losses incurred by Sipora Pty Ltd
            and its officers, employees and agents in this Agreement are
            continuing obligations of the parties, separate and independent from
            their other obligations and survive termination of this Agreement
            and absolute and unconditional and unaffected by anything that might
            have the effect of prejudicing, releasing, discharging or affecting
            in any other way the liability of the party that is obliged to
            provide the compensation.
          </MediumText>
        </Unorderedlist>

        {/* item 4 */}
        <Unorderedlist>
          <MediumText>
            This Agreement is governed by the laws of New South Wales,
            Australia, and the parties submit to the non- exclusive jurisdiction
            of the courts of New South Wales and waive, without limitation, any
            claim or objection based on absence of jurisdiction or inconvenient
            forum.
          </MediumText>
        </Unorderedlist>
      </Unorderedlist>

      {/* list */}
      <Unorderedlist>
        <MediumText style={t.fontBold}>Important Acknowledgements</MediumText>
        {/* list */}
        {/* item 1 */}
        <Unorderedlist>
          <MediumText>
            By applying for an Sipora Pty Ltd App Account:
          </MediumText>

          {/* item 1 */}
          <Unorderedlist>
            <MediumText>
              you consent to receive any and all advice, documents, information,
              or other communications from Sipora Pty Ltd electronically through
              the Website, the App, email or otherwise over the internet;
            </MediumText>
          </Unorderedlist>
          {/* item 2 */}
          <Unorderedlist>
            <MediumText>
              you agree and acknowledge that you must have a direct debit
              arrangement in place, provided at the time of account
              establishment
            </MediumText>
          </Unorderedlist>
          {/* item 3 */}
          <Unorderedlist>
            <MediumText>
              you agree and acknowledge that you must use the App to connect to
              a Funding Account to make contributions;
            </MediumText>
          </Unorderedlist>
          {/* item 4 */}
          <Unorderedlist>
            <MediumText>
              you confirm that any information you enter into the App about your
              Funding Account and Spending Account, will be true, accurate,
              current, and complete;
            </MediumText>
          </Unorderedlist>
          {/* item 5 */}
          <Unorderedlist>
            <MediumText>
              you represent that your Funding Account is not, and will never be,
              a credit card, overdraft amount or any other borrowed money.
            </MediumText>
          </Unorderedlist>
          {/* item 6 */}
          <Unorderedlist>
            <MediumText>
              you agree and acknowledge that we will use your personal
              information to send you messages containing important information
              about your Sipora Pty Ltd account. These messages are an important
              part of the service that we provide to you, and will be sent to
              you as long as you hold an Sipora Pty Ltd Savings Account. You
              cannot opt- out of receiving these messages; and
            </MediumText>
          </Unorderedlist>
        </Unorderedlist>
      </Unorderedlist>

      {/* list */}
      <Unorderedlist>
        <MediumText style={t.fontBold}>
          Direct Debit Request Service Agreement
        </MediumText>
        {/* list */}
        {/* item 1 */}
        <Unorderedlist>
          <MediumText style={t.fontBold}>
            Definitions in this agreement
          </MediumText>

          {/* item 1 */}
          <Unorderedlist>
            <MediumText>
              a. agreement means this Direct Debit Request Service Agreement
              between us and you
            </MediumText>
          </Unorderedlist>
          {/* item 2 */}
          <Unorderedlist>
            <MediumText>
              b. debit arrangements means the debit arrangements that apply
              between us and you as set out in this agreement and the direct
              debit request
            </MediumText>
          </Unorderedlist>
          {/* item 3 */}
          <Unorderedlist>
            <MediumText>
              c. debit day means a day that your account is due to be debited by
              us
            </MediumText>
          </Unorderedlist>
          {/* item 4 */}
          <Unorderedlist>
            <MediumText>
              d. debit payment means a particular debit from your account
            </MediumText>
          </Unorderedlist>
          {/* item 5 */}
          <Unorderedlist>
            <MediumText>
              e. direct debit request means the Direct Debit Request made to us
              by you
            </MediumText>
          </Unorderedlist>
          {/* item 6 */}
          <Unorderedlist>
            <MediumText>f. we, us or our means Sipora Pty Ltd</MediumText>
          </Unorderedlist>
          {/* item 7 */}
          <Unorderedlist>
            <MediumText>
              g. your account means the Funding Account(s) identified by you in
              the direct debit request from which you have authorised and
              requested us to debit funds
            </MediumText>
          </Unorderedlist>
          {/* item 8 */}
          <Unorderedlist>
            <MediumText>
              h. your financial institution means the financial institution
              identified by you in the direct debit request which holds your
              account
            </MediumText>
          </Unorderedlist>
        </Unorderedlist>

        {/* list */}
        {/* item 2 */}
        <Unorderedlist>
          <MediumText style={t.fontBold}>Debit arrangements</MediumText>

          {/* item 1 */}
          <Unorderedlist>
            <MediumText>
              a. By applying for an Sipora Pty Ltd account, you agree to the
              terms of this agreement and authorise and request us to debit from
              your account any amount payable to us by you, including any
              payable Maintenance Fees
            </MediumText>
          </Unorderedlist>
          {/* item 2 */}
          <Unorderedlist>
            <MediumText>
              b. If the debit day is not a Business Day, we may debit your
              account on the next Business Day. If you are uncertain about when
              a debit payment has been or will be processed, you should contact
              your financial institution.
            </MediumText>
          </Unorderedlist>
        </Unorderedlist>

        {/* list */}
        {/* item 3 */}
        <Unorderedlist>
          <MediumText style={t.fontBold}>Variation</MediumText>

          {/* item 1 */}
          <Unorderedlist>
            <MediumText>
              a. We may vary or cancel the debit arrangements at any time if we
              have given you at least 14 days' notice of the variation or
              cancellation.
            </MediumText>
          </Unorderedlist>
          {/* item 2 */}
          <Unorderedlist>
            <MediumText>
              b. You may, in writing (including via email), ask for the debit
              arrangements to be altered. If you request a variation in the
              debit arrangements, you will be required to provide verification
              information again.
            </MediumText>
          </Unorderedlist>
        </Unorderedlist>

        {/* list */}
        {/* item 4 */}
        <Unorderedlist>
          <MediumText style={t.fontBold}>Cancellation</MediumText>

          {/* item 1 */}
          <Unorderedlist>
            <MediumText>
              a. You may cancel your authority for us to debit your account at
              any time by giving us at least 14 days notice in writing. You may
              cancel the debit arrangements by contacting your financial
              institution.
            </MediumText>
          </Unorderedlist>
          {/* item 2 */}
          <Unorderedlist>
            <MediumText>
              b. If you cancel the debit arrangements with your financial
              institution, you must make other arrangements with an alternative
              financial instruction
            </MediumText>
          </Unorderedlist>
          {/* item 3 */}
          <Unorderedlist>
            <MediumText>
              c. You must have a direct debit arrangement in place to operate
              your Sipora Pty Ltd Account.
            </MediumText>
          </Unorderedlist>
        </Unorderedlist>

        {/* list */}
        {/* item 5 */}
        <Unorderedlist>
          <MediumText style={t.fontBold}>Disputes</MediumText>

          {/* item 1 */}
          <Unorderedlist>
            <MediumText>
              a. If you believe that we have debited your account incorrectly,
              contact us using the contact link at the beginning of this page,
              or go to the Help page, as soon as possible. We may ask you to
              confirm your query in writing. We will investigate your query and
              notify you in writing of the action we have taken within 21 days.
            </MediumText>
          </Unorderedlist>
          {/* item 2 */}
          <Unorderedlist>
            <MediumText>
              b. If we find that the debit was made incorrectly, we will adjust
              your account accordingly (including in relation to interest and
              charges). To the extent permitted by law, we will not be liable
              for any other losses suffered by you. If we find that the debit
              was made correctly, we will provide you with reasons and any
              evidence for this finding.
            </MediumText>
          </Unorderedlist>
          {/* item 3 */}
          <Unorderedlist>
            <MediumText>
              c. You may also ask your financial institution to query a debit on
              your behalf.
            </MediumText>
          </Unorderedlist>
        </Unorderedlist>

        {/* list */}
        {/* item 6 */}
        <Unorderedlist>
          <MediumText style={t.fontBold}>Your account</MediumText>

          {/* item 1 */}
          <Unorderedlist>
            <MediumText>
              a. Direct debiting is not available on all accounts and you should
              check with your financial institution whether direct debiting is
              available on your account.
            </MediumText>
          </Unorderedlist>
          {/* item 2 */}
          <Unorderedlist>
            <MediumText>
              b. It is your responsibility to ensure that the account details
              provided to us in the direct debit request are correct. You should
              check those account details against a recent statement from your
              financial institution.
            </MediumText>
          </Unorderedlist>
          {/* item 3 */}
          <Unorderedlist>
            <MediumText>
              c. If you are uncertain about the debit arrangements, you should
              check with your financial institution before completing the direct
              debit request.
            </MediumText>
          </Unorderedlist>
          {/* item 4 */}
          <Unorderedlist>
            <MediumText>
              d. It is your responsibility to ensure that your account has
              sufficient clear funds available on a debit day to permit a debit
              payment to be made in accordance with the direct debit request.
            </MediumText>
          </Unorderedlist>
        </Unorderedlist>

        {/* list */}
        {/* item 7 */}
        <Unorderedlist>
          <MediumText style={t.fontBold}>Failed payments</MediumText>

          {/* item 1 */}
          <Unorderedlist>
            <MediumText>
              a. If your financial institution does not permit us to process a
              debit payment on a debit day (whether because there are
              insufficient clear funds or for any other reason), the following
              terms apply.
            </MediumText>
          </Unorderedlist>
          {/* item 2 */}
          <Unorderedlist>
            <MediumText>
              b. We will notify you in writing and we may require you to pay
              failed payment fees and charges.
            </MediumText>
          </Unorderedlist>
          {/* item 3 */}
          <Unorderedlist>
            <MediumText>
              c. Your financial institution may also require you to pay fees,
              charges and interest.
            </MediumText>
          </Unorderedlist>
        </Unorderedlist>

        {/* list */}
        {/* item 8 */}
        <Unorderedlist>
          <MediumText style={t.fontBold}>Notices</MediumText>

          {/* item 1 */}
          <Unorderedlist>
            <MediumText>
              a. If you are required or wish to give us written notice in
              relation to the debit arrangements, use the App or the contact
              details on the front of this PDS. We will send notices to you by
              email to the address that you have provided to us.
            </MediumText>
          </Unorderedlist>
        </Unorderedlist>
      </Unorderedlist>
    </>
  );
};
