import React from "react";
import { KeyboardType, Text, TextInput, View, ImageSourcePropType } from "react-native";
import { t } from "react-native-tailwindcss";
import InputIcon from "../atoms/InputIcon";

interface Props {
    error?: string;
    style?: Record<string, string>;
    editable?: boolean;
    value?: string;
    placeholder?: string;
    onChangeText?: (text: string) => void;
    secureTextEntry?: boolean;
    type?: KeyboardType;
    sourceIcon: ImageSourcePropType
}

const LoginInput = ({
    editable = true,
    type = "default",
    style,
    sourceIcon,
    ...props
}: Props): React.ReactElement => {
    return (
        <View>
            <View style={styles.wrapper}>
                <View style={{ width: 37, height: '100%', position: 'absolute', left: 5 }}>
                    <InputIcon sourceIcon={sourceIcon} />
                </View>
                <TextInput
                    style={[
                        styles.input,
                        (props.error ? { borderWidth: 1, borderColor: '#f56565' } : {}),
                        !editable && styles.disabled,
                        style,
                    ]}
                    keyboardType={type}
                    autoCapitalize="none"
                    placeholderTextColor={t.fontLight.color}
                    editable={editable}
                    {...props}
                />
            </View>
            {props.error && <Text style={styles.errorText}>{props.error}</Text>}
        </View>
    );
};

export default LoginInput;

const styles = {
    wrapper: [t.selfStretch, t.mB2],
    input: [
        t.textBase,
        t.textGray700,
        t.wFull,
        t.flexRow,
        t.bgWhite,
        t.h12,
        t.selfStretch,
        t.pL12,
        t.rounded,
        t.shadowLg
    ],
    errorText: [t.mT1, t.textRed500],
    disabled: [{ backgroundColor: "#d3d3d3" }],
};
