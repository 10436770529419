import React from "react";
import { Image, ImageSourcePropType } from "react-native";

const InputIcon = ({ sourceIcon }: { sourceIcon: ImageSourcePropType }): React.ReactElement => {
    return (
        <Image
            source={sourceIcon}
            style={{
                width: "100%",
                height: "100%",
                resizeMode: "contain",
            }}
        />
    );
};

export default InputIcon;