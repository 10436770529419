import React, { useEffect, useState } from "react";
import { View, ActivityIndicator } from "react-native";
import { t } from "react-native-tailwindcss";
import Card from "../../../atoms/Card";
import Currency from "../../../atoms/Currency";
import ClientLogo from "../../../atoms/ClientLogo";
import usePayments from "../../../../hooks/usePayments";
import { HCenterStack, HStack } from "../../../layout/HStack";
import { MediumText } from "../../../atoms/MediumText";
import { LineTitle } from "../../../molecules/LineTitle";
import { LinkText } from "../../../atoms/LinkText";
import { LargeText } from "../../../atoms/LargeText";
import { TransactionStatus } from "../../../../dtos/payment";
import { useUser } from '../../../../context/userContext';
import { useClubList } from '../../../../context/clubContext';
import { Club } from "../../../../dtos/job";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { AppScreensParamList } from "../../../../navigation/app-screens";

export const TotalAmounts = (): React.ReactElement => {
  const { payments } = usePayments();
  const { user, isUserLoading } = useUser();
  const { clubList, isClubsLoading } = useClubList();
  const navigation = useNavigation<NavigationProp<AppScreensParamList>>();
  const [selctedClub, setSelectedClub] = useState<Club | null>(null);

  useEffect(() => {
    clubList?.map(item => {
      if (item._id === user?.supportingClubId) {
        setSelectedClub(item);
      }
    })
  }, [user, clubList])

  const filteredPayments = payments.filter(
    (p) =>
      TransactionStatus.CLEARED ||
      TransactionStatus.MATURED ||
      TransactionStatus.PROCESSING ||
      TransactionStatus.CLEARING
  );

  const totalCleared = filteredPayments.reduce((acc, payment) => {
    return acc + payment.totalDebitAmount;
  }, 0);

  return (
    <Card style={styles.card}>
      <HCenterStack>
        <View style={{ height: 120, width: 120 }}>
          <ClientLogo />
        </View>
      </HCenterStack>
      <HCenterStack>
        <MediumText style={{
          marginHorizontal: 10,
          paddingTop: 30,
          fontSize: 23,
          fontWeight: '500',
          color: '#FFFFFF'
        }}>
          <Currency cents={totalCleared} />
        </MediumText>
      </HCenterStack>
      <HCenterStack>
        <MediumText style={{ color: '#FFFFFF', paddingBottom: 20, }}>
          Total Donations
        </MediumText>
      </HCenterStack>
    </Card>
  );
};
const styles = {
  card: [t.flex, t.flexCol, t.bgTransparent],
};

export default TotalAmounts;
