import { LinkingOptions } from "@react-navigation/native";
import * as Linking from "expo-linking";

const Authenticated = {
  screens: {
    Dashboard: "/dashboard",
    RoundupAccounts: "/roundups",
    FundingAccount: "/fundings",
    Help: "/help-and-support",
    PrivacyPolicy: "/policy",
    Terms: "/terms",
    Profile: "/profile",
    PayNow: "/pay-now",
    Transactions: "/transactions",
    LinkedSavingsAccount: "/linked-savings-account",
  },
};

const Onboarding = {
  screens: {
    AccountSetup: "/account-setup",
    RoundupAccountsSetup: "/roundup-accounts",
    FundingAccountSetup: "/funding-accounts",
    FinishOnboarding: "/finish-onboarding",
    Terms: "/term-and-conditions",
    PrivacyPolicy: "/privacy-policy-docs",
    Help: "/help-support",
  },
};

const Unauthenticated = {
  screens: {
    Login: "/",
    Registration: "/register",
    ForgotPassword: "/forgot-password",
    Help: "/help",
    PrivacyPolicy: "/privacy-policy",
    Terms: "/terms-and-conditions",
    VerifyEmail: "/verify-email"
  },
};

const BasiqConsent = {
  screens: {
    Welcome: "/welcome",
  },
};

const Errors = {
  screens: {
    Welcome: "/error",
  },
};

export const linkingConfiguration: LinkingOptions = {
  prefixes: [Linking.createURL("/")],
  config: {
    screens: {
      Unauthenticated,
      BasiqConsent,
      Onboarding,
      Authenticated,
      Errors,
      NotFound: "*",
    },
  },
};
