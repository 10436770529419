import React from "react";
import { Text } from "react-native";
import NumberFormat from "react-number-format";
import { t } from "react-native-tailwindcss";

export const Currency = ({
  cents = 0,
  isBold = false,
}: {
  cents: number;
  isBold?: boolean;
}): React.ReactElement => {
  const dollarValue = cents / 100;

  const formattedValue = (Math.round(dollarValue * 100) / 100).toFixed(2);
  return (
    <NumberFormat
      value={formattedValue}
      displayType="text"
      thousandSeparator
      prefix="$"
      renderText={(value) => (
        <Text {...(isBold ? { style: styles.boldCurrency } : {})}>{value}</Text>
      )}
    />
  );
};

const styles = {
  boldCurrency: [t.fontBold],
};

export default Currency;
