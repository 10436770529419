import { appConfig } from "../config/config";
import api from "./api/api";
import utils from "../utils";
import { User } from "../dtos/user";
import { EnableLinkAccount, Job, LinkAccount } from "../dtos/job";

export interface CreateUserParams {
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
}
export type UpdateUserResponse = {
  message: string;
};

export type CreateUserRequest = {
  firstName: string;
  lastName: string;
  mobile: string;
  country: string;
  contributionLimit?: number;
  customerReference?: string;
};

type CreateUserResponse = {
  id: string;
};

export const createUser = async (
  payload: CreateUserRequest
): Promise<CreateUserResponse> => {
  return api.post(`user/${appConfig.tenant}/user/create`, {
    ...payload,
  });
};

export const finishOnboarding = async (): Promise<void> => {
  await api.put(`user/${appConfig.tenant}/user/finishOnboarding`);
};

export const updateUser = async (
  payload: CreateUserParams
): Promise<UpdateUserResponse> => {
  return api.put(`user/${appConfig.tenant}/user/update`, {
    ...payload,
  });
};

export const getUser = async (): Promise<User | null> => {
  const hasAuthToken = await utils.hasAuthToken();
  if (!hasAuthToken) return null;

  return api.get<User>(`basiq-user/${appConfig.tenant}/user/get`);
};

export const createBasiqBankJob = async (): Promise<Job | null> => {
  const hasAuthToken = await utils.hasAuthToken();
  if (!hasAuthToken) return null;

  return api.get<Job>(`basiq-bank/${appConfig.tenant}/banks/user/connection`);
};

export const updateBasiqBankJob = async (
  userParams: object = {}
): Promise<Job | null> => {
  const hasAuthToken = await utils.hasAuthToken();
  if (!hasAuthToken) return null;

  return api.post<Job>(
    `basiq-bank/${appConfig.tenant}/banks/user/new-consent`,
    {
      ...userParams,
    }
  );
};

export const updateUserConsent = async (
  isConsent: boolean
): Promise<User | null> => {
  const hasAuthToken = await utils.hasAuthToken();
  if (!hasAuthToken) return null;

  return api.put<User>(`user/${appConfig.tenant}/user/update`, {
    isConsent,
  });
};

export const setRoundupAmount = async (amount: number): Promise<User> => {
  return api.post(`user/${appConfig.tenant}/user/roundup`, {
    roundupAmountCents: amount,
  });
};

export const setWeeklyMaximumLimit = async (limitAmt: number): Promise<User> => {
  return api.post(`user/${appConfig.tenant}/user/weekly-maximum-limit`, {
    weeklyMaximumLimit: limitAmt,
  });
};

export const setDirectDebit = async (
  debitSchedule: "weekly" | "monthly" | undefined,
  debitAmountCents: number
): Promise<User> => {
  return api.post(`user/${appConfig.tenant}/user/debit`, {
    debitSchedule,
    debitAmountCents,
  });
};

export const setDebitAccountJob = async (accountId: string): Promise<Job> => {
  return api.post<Job>(`user/${appConfig.tenant}/user/debitaccount`, {
    accountId,
  });
};
export const setBasiqDebitAccountJob = async (
  accountId: string
): Promise<Job> => {
  return api.post<Job>(`basiq-user/${appConfig.tenant}/user/debitaccount`, {
    accountId,
  });
};

export const basiqTransactionOccurrence = async (
  syncBeginningOfMonth: boolean
) => {
  return api.post<Job>(`user/${appConfig.tenant}/user/transaction-occurence`, {
    syncBeginningOfMonth,
  });
};

export const getLinkedSavingsAccount = async () => {
  return api.get<LinkAccount>(
    `user/${appConfig.tenant}/user/savings-account/get`
  );
};

export const linkedSavingsAccount = async ({
  bsb,
  accountName,
  accountNumber
}: LinkAccount) => {
  return api.post<LinkAccount>(
    `user/${appConfig.tenant}/user/savings-account/upsert`,
    {
      bsb,
      accountNumber,
      accountName
    }
  );
};

export const updateIslinkedSavingsAccEnabled = async ({
  savingsAccountEnabled
}: EnableLinkAccount) => {
  return api.post<EnableLinkAccount>(
    `user/${appConfig.tenant}/user/savings-account/enabled`,
    {
      savingsAccountEnabled
    }
  );
};

export const updateConsent = async () => {
  const hasAuthToken = await utils.hasAuthToken();
  if (!hasAuthToken) return null;

  return api.get<Job>(
    `basiq-bank/${appConfig.tenant}/banks/user/consent/update`
  );
};

export const refreshConnection = async () => {
  const hasAuthToken = await utils.hasAuthToken();
  if (!hasAuthToken) return null;

  return api.get<Job>(
    `basiq-bank/${appConfig.tenant}/banks/user/connections/refresh`
  );
};
