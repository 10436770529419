import React from "react";
import { Image } from "react-native";
import logo from "../../assets/images/logo.png";

const ClientLogo = (): React.ReactElement => {
  return (
    <Image
      source={logo}
      style={{
        width: "100%",
        height: "100%",
        resizeMode: "contain",
      }}
    />
  );
};

export default ClientLogo;
