import { Ionicons, Entypo } from "@expo/vector-icons";
import React from "react";
import { ActivityIndicator, View } from "react-native";
import { t } from "react-native-tailwindcss";
import { JobStatus } from "../../dtos/job";
import { UseJobHookInfo } from "../../hooks/useJob";
import { MediumText } from "../atoms/MediumText";
import { HStack } from "../layout/HStack";

type Props = {
  steps: { title: string; context: UseJobHookInfo | { status: any } }[];
  description: string;
  isOnboarding?: boolean;
};

const StatusIcon = ({ status }: { status?: JobStatus }) => {
  switch (status) {
    case JobStatus.Success:
      return <Ionicons name="checkmark-sharp" size={24} color="#52db3e" />;
    case JobStatus.Fail:
      return <Entypo name="cross" size={24} color="#DB3E52" />;
    case JobStatus.Pending:
      return <ActivityIndicator />;
    default:
      return <ActivityIndicator />
  }
};

export const JobFeedback = ({
  steps,
  description,
  isOnboarding = false,
}: Props): React.ReactElement => {
  return (
    <>
      <MediumText style={{ paddingBottom: 30 }}>{description}</MediumText>
      {steps.map((step, index) => (
        <View key={index}>
          <HStack style={{ marginBottom: 8, justifyContent: "space-between" }}>
            <HStack>
              {isOnboarding ? null :
                <Ionicons
                  name="lock-closed"
                  size={20}
                  color="#DB3E52"
                  style={[t.mR2, t._mT1]}
                />
              }
              <MediumText>{step.title}</MediumText>
            </HStack>

            <StatusIcon status={step.context.status} />
          </HStack>
        </View>
      ))}
    </>
  );
};
