import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Text, Animated, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { t } from "react-native-tailwindcss";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { useToast } from "react-native-toast-notifications";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import ClientLogo from "../../../atoms/ClientLogo";
import { AuthFooter } from "../../../organisms/AuthFooter";
import { authStyles } from "../shared";
import { ResetPasswordForm } from "./ResetPasswordForm";
import { HCenterStack } from "../../../layout/HStack";
import { ScreenContainer } from "../../../layout/ScreenContainer";
import { AuthScreensParamList } from "../../../../navigation/auth-screens";
import { firebaseApp } from "../../../../config/firebase";

import { Spinner } from "../../../organisms/Spinner";

export type ResetPasswordFormData = {
  email: string;
};

export type CustomErrors = {
  passwordMatch?: boolean;
  general?: string;
};

export const ForgotPasswordScreen = (): React.ReactElement => {
  const [loading, setLoading] = useState(false);
  const [customErrors, setCustomErrors] = useState<CustomErrors>({});
  const navigation = useNavigation<NavigationProp<AuthScreensParamList>>();
  const toast = useToast();

  // Form stage transition animation
  const [fadeAnim, setFadeAnim] = useState(new Animated.Value(1));
  useEffect(() => {
    // on changes to formAnim value, animate it back to full opacity
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 500,
      useNativeDriver: false,
    }).start();
  }, [fadeAnim]);
  useEffect(() => {
    // trigger form animation on form stage change
    setFadeAnim(new Animated.Value(0.1));
  }, []);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: { email: "" } as ResetPasswordFormData,
    // this unregisters the form from resetting default values on submit
    shouldUnregister: false,
  });

  const mapAuthCodeToMessage = (authCode: any) => {
    if (authCode?.code) {
      switch (authCode?.code) {
        case "auth/invalid-password":
          return "Password provided is not corrected";

        case "auth/invalid-email":
          return "Provided email is invalid";

        case "auth/user-not-found":
          return "Provided email not found";

        case "auth/wrong-password":
          return "Password is invalid";

        default:
          return authCode?.message;
      }
    } else {
      return authCode?.message;
    }
  }

  const resetPassword = async (data: ResetPasswordFormData): Promise<void> => {
    const newCustomErrors: CustomErrors = {
      ...customErrors,
      general: undefined,
    };
    setCustomErrors(newCustomErrors);

    setLoading(true);
    try {
      const auth = getAuth(firebaseApp);
      await sendPasswordResetEmail(auth, data.email);
      toast.show("Password reset email sent!", {
        type: "success",
      });
      navigation.navigate("Login");
    } catch (error: any) {
      setCustomErrors({
        ...customErrors,
        general:
          mapAuthCodeToMessage(error ?? "Something went wrong, please contact support"),
      });
    }
    setLoading(false);
  };

  return (
    <ScrollView>
      <ScreenContainer center>
        <KeyboardAwareScrollView
          keyboardShouldPersistTaps="always"
          scrollEnabled={false}
          resetScrollToCoords={{ x: 0, y: 0 }}
          contentContainerStyle={authStyles.scrollView}
          extraScrollHeight={60}
        >
          {loading ? (
            <Spinner />
          ) : (
            <>
              <HCenterStack style={authStyles.imageContainer}>
                <View style={{ width: "50%", height: "100%" }}>
                  <ClientLogo />
                </View>
              </HCenterStack>
              <Animated.View // Special animatable View
                style={{
                  opacity: fadeAnim, // Bind opacity to animated value
                }}
              >
                <ResetPasswordForm
                  onSubmit={resetPassword}
                  handleSubmit={handleSubmit}
                  control={control}
                  errors={errors}
                />
              </Animated.View>

              {customErrors.general && (
                <Text style={[authStyles.error, t.textCenter, t.mT2]}>
                  {customErrors.general}
                </Text>
              )}

              <AuthFooter
                prompts={[
                  {
                    promptText: "Take me back to",
                    promptLink: "Login",
                    promptLinkText: "login",
                  },
                ]}
              />
            </>
          )}
        </KeyboardAwareScrollView>
      </ScreenContainer>
    </ScrollView>
  );
};
