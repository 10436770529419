import { ErrorCode } from "../services/api/api";

export enum JobStatus {
  Pending = "pending",
  Success = "success",
  Fail = "fail",
}

export enum JobType {
  Connect = "CreateConnection",
  Sync = "SyncAccounts",
  SetFundingAccount = "SetDebitAccount",
}

export interface JobResponse {
  status: string;
  userId: string;
  _createdDate: Date;
  _flags: {
    active: boolean;
  };
  _id: string;
}

export type Job = {
  id: string;
  type: JobType;
  status: JobStatus;
  failReason?: ErrorCode;
} & JobResponse;

export type LinkAccount = {
  bsb: string;
  accountNumber: string;
  accountName: string;
};

export type EnableLinkAccount = {
  savingsAccountEnabled: boolean;
};

export type Club = {
  _id: string;
  name: string;
};

export type UpdateClub = {
  supportingClubId: string;
};