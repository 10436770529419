import React, { useEffect, useState } from "react";
import styled from "styled-components/native";
import { View, ActivityIndicator } from "react-native";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { ScrollView } from "react-native-gesture-handler";
import { t } from "react-native-tailwindcss";
import { useQueryClient, useMutation } from "react-query";
import { useToast } from "react-native-toast-notifications";
import { ScreenContainer } from "../../../layout/ScreenContainer";
import { ExtraLargeText } from "../../../atoms/ExtraLargeText";
import { Footer } from "../../../molecules/Footer";
import { MediumText } from "../../../atoms/MediumText";
import Box from "../../../atoms/Box";
import { HCenterStack } from "../../../layout/HStack";
import { useUser } from "../../../../context/userContext";
import { SetupScreensParamList } from "../../../../navigation/setup-screens";
import { RoundupAccount } from "../../App/RoundupAccounts/RoundupAccount";
import Button from "../../../atoms/Button";
import { VStack } from "../../../layout/VStack";
import { LineTitle } from "../../../molecules/LineTitle";
import { basiqUpdateAccount } from "../../../../services/bankService";
import { setRoundupAmount } from "../../../../services/userService";
import { QueryKey } from "../../../../services/api/query";
import { useAccounts } from "../../../../context/accountsContext";
import { getErrorMessage, ApiError } from "../../../../services/api/api";
import ClientLogo from "../../../atoms/ClientLogo";
import { appConfig } from "../../../../config/config";
import { authStyles } from "../../Auth/shared";

const StyledRoundupAccountsContentContainer = styled.View`
  margin-top: 1px;
`;

const StyledFooterContainer = styled.View`
  margin-top: 30px;
  margin-Bottom: 20px;
`;

const StyledRoundupAmountContainer = styled(VStack)`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const StyledRoundupAmountSelectorContainer = styled(HCenterStack)`
  width: 100%;
`;

export const RoundupAccountsSetupScreen = (): React.ReactElement => {
  const toast = useToast();
  const navigation = useNavigation<NavigationProp<SetupScreensParamList>>();
  const queryClient = useQueryClient();
  const { user, isUserLoading } = useUser();
  const { accounts, isAccountsLoading } = useAccounts();
  const [roundupSetupComplete, setRoundupSetupComplete] =
    useState<boolean>(false);

  const [roundupAmountCents, setRoundupAmountCents] = useState<number>(0);

  const roundUpAmtList: { title: string, value: number }[] = [
    {
      title: "$1",
      value: 100,
    },
    {
      title: "$2",
      value: 200,
    },
    {
      title: "$5",
      value: 500,
    },
  ]

  useEffect(() => {
    setRoundupAmountCents(user?.roundupAmountCents ?? 100);
  }, [user]);

  useEffect(() => {
    setRoundupAmount(user?.roundupAmountCents ?? 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const hasAtLeastOneRoundupAccountSelected = !!accounts?.find(
      (a) => a.activeRoundUpsEnabled
    );

    const hasRoundupAmountSelected = !!roundupAmountCents;
    setRoundupSetupComplete(
      hasAtLeastOneRoundupAccountSelected && hasRoundupAmountSelected
    );
  }, [accounts, roundupAmountCents]);

  const mutation = useMutation(basiqUpdateAccount, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(QueryKey.Accounts);
    },
    onError: (error: ApiError & Error) => {
      console.log(getErrorMessage(error));
      toast.show("Something went wrong", {
        type: "danger",
      });
    },
  });

  const roundUpMutation = useMutation(setRoundupAmount, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(QueryKey.User);
    },
    onError: (error: ApiError & Error) => {
      console.log(getErrorMessage(error));
      toast.show("Something went wrong", {
        type: "danger",
      });
    },
  });

  const toggleAccount = async (accountId: string, enabled: boolean) => {
    mutation.mutate({
      accountId: accountId,
      activeRoundUpsEnabled: enabled
    });
  };

  const setRoundups = async (amount: number): Promise<void> => {
    roundUpMutation.mutate(amount);
  };

  return (
    <ScrollView>
      <ScreenContainer backgroundColor="#ffffff" padding={1}>
        <View style={{
          backgroundColor: appConfig.dashboardBackground,
          paddingHorizontal: 40,
          paddingVertical: 20
        }}>
          <HCenterStack style={authStyles.imageContainer}>
            <View style={{ width: "70%" }}>
              <ClientLogo />
            </View>
          </HCenterStack>
          <ExtraLargeText style={{ fontSize: 23, fontWeight: '500' }} center>Select Your Round-Up Accounts</ExtraLargeText>
          <MediumText style={{ textAlign: 'center' }}>Select, from the list below, which accounts you would like to track round-ups.</MediumText>
          <MediumText style={{ textAlign: 'center' }}>
            You can select multiple round-up accounts.
          </MediumText>
        </View>

        <StyledRoundupAccountsContentContainer>
          <StyledRoundupAmountContainer>
            <LineTitle title="Amount" />
            {roundUpMutation.isLoading || isUserLoading ?
              <View style={{ justifyContent: 'center' }}>
                <ActivityIndicator color={appConfig.primaryColour} />
              </View>
              :
              <StyledRoundupAmountSelectorContainer>
                {roundUpAmtList.map((b, i) => (
                  <Box
                    title={b.title}
                    checked={b.value === roundupAmountCents}
                    onPress={() => {
                      setRoundupAmountCents(b.value);
                      setRoundups(b.value);
                    }}
                    key={i.toString()}
                  />
                ))}
              </StyledRoundupAmountSelectorContainer>
            }
          </StyledRoundupAmountContainer>

          <LineTitle title="Accounts" />

          <View style={{ paddingHorizontal: 40 }}>

            {mutation.isLoading || isAccountsLoading ?
              <View style={{ height: (60 * (accounts?.length ?? 1)), justifyContent: 'center' }}>
                <ActivityIndicator size={"large"} color={appConfig.primaryColour} />
              </View>
              :
              <View style={{ paddingVertical: 30 }}>
                {accounts?.map((account) => (
                  <RoundupAccount
                    account={account}
                    key={account.id}
                    checked={account.activeRoundUpsEnabled}
                    updateAccount={toggleAccount}
                  />
                ))}
              </View>
            }

            <View style={styles.buttonContainer}>
              <Button
                label="Next"
                onPress={() => navigation.navigate("FundingAccountSetup")}
                disabled={!roundupSetupComplete}
                colour={!roundupSetupComplete ? "gray" : undefined}
              />
            </View>
          </View>
        </StyledRoundupAccountsContentContainer>

        <StyledFooterContainer>
          <Footer />
        </StyledFooterContainer>
      </ScreenContainer>
    </ScrollView>

  );
};

const styles = {
  buttonContainer: [t.pL0, t.pR0],
};
