import { useEffect, useState } from "react";
import {
  isSuccessOrPendingPaymentRequest,
  Payment,
  TransactionStatus,
} from "../dtos/payment";
import { usePaymentsQuery } from "./queries/usePaymentsQuery";

type UsePaymentsHookInfo = {
  payments: Payment[];
  totalCleared: number;
  totalClearedAndPending: number;
};

const usePayments = (): UsePaymentsHookInfo => {
  const [payments, setPayments] = useState<Payment[]>([]);
  const { data } = usePaymentsQuery();
  const [totalCleared, setTotalCleared] = useState(0);
  const [totalClearedAndPending, setTotalClearedAndPending] = useState(0);

  useEffect(() => {
    const getPaymentsAndTotals = async (): Promise<void> => {
      try {
        setPayments(data || []);

        const calcTotalCleared = (data || [])
          .filter(
            (p) =>
              p.status ===
              (TransactionStatus.CLEARED ||
                TransactionStatus.MATURED ||
                TransactionStatus.PROCESSING ||
                TransactionStatus.CLEARING)
          )
          .reduce((acc, payment) => {
            return acc + payment.totalDebitAmount;
          }, 0);

        setTotalCleared(calcTotalCleared);

        const calcTotalClearedAndPending = (data || [])
          .filter((p) => {
            return isSuccessOrPendingPaymentRequest(p.status);
          })
          .reduce((acc, payment) => {
            return acc + payment.totalDebitAmount;
          }, 0);
        setTotalClearedAndPending(calcTotalClearedAndPending);
      } catch (error) {
        console.error(error);
      }
    };

    // get payments and totals
    getPaymentsAndTotals();
  }, [data]);

  return { payments, totalCleared, totalClearedAndPending };
};

export default usePayments;
