import React from "react";
import { View, StyleSheet, ViewStyle } from "react-native";

interface ComponentSpecificProps {
  style: ViewStyle | ViewStyle[];
  cornerRadius?: number;
}

type Props = React.PropsWithChildren<ComponentSpecificProps>;

const Card = ({
  style,
  // eslint-disable-next-line react/prop-types
  children,
  cornerRadius = 12,
}: Props): React.ReactElement => {
  const cardStyles = StyleSheet.create({
    container: {
      borderRadius: cornerRadius,
    },
  });

  // requires test
  return <View style={[cardStyles.container, style && style]}>{children}</View>;
};

export default Card;
