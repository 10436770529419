import React, { FunctionComponent, useEffect, useState } from "react";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { Platform, useWindowDimensions } from "react-native";

import {
  hasWidthAvailableForAppAndSidebar,
  WEB_HEADER_HEIGHT,
  WEB_SIDEBAR_WIDTH,
  requiresLeftAnchor
} from "../../web-helper";
import { HelpScreen } from "../../components/screens/Shared/Help/HelpScreen";
import { PrivacyPolicyScreen } from "../../components/screens/Shared/PrivacyPolicy/PrivacyPolicyScreen";
import { TermsScreen } from "../../components/screens/Shared/Terms/TermsScreen";
import { DashboardScreen } from "../../components/screens/App/Dashboard/DashboardScreen";
import { RoundupAccountsScreen } from "../../components/screens/App/RoundupAccounts/RoundupAccountsScreen";
import { FundingAccountScreen } from "../../components/screens/App/FundingAccount/FundingAccountScreen";
import { ProfileScreen } from "../../components/screens/App/Profile/Profile";
import { TransactionsScreen } from "../../components/screens/App/Transactions/TransactionsScreen";
import { PayNowScreen } from "../../components/screens/App/PayNow/PayNowScreen";
import { LinkedSavingsAccountScreen } from "../../components/screens/App/LinkedSavingsAccount/LinkedSavingsAccountScreen";
import MenuButton from "../../components/atoms/MenuButton";
import { DrawerWithLogoutButton } from "../../navigation/DrawerWithLogoutButton";

const authenticatedScreenList: IScreenRoute[] = [
  {
    name: "Dashboard",
    component: DashboardScreen,
    appearsInDrawer: true,
    options: () => ({ headerShown: true, title: "Dashboard" }),
  },
  {
    name: "RoundupAccounts",
    component: RoundupAccountsScreen,
    appearsInDrawer: true,
    options: () => ({ headerShown: true, title: "Round-Ups" }),
  },
  {
    name: "FundingAccount",
    component: FundingAccountScreen,
    appearsInDrawer: true,
    options: () => ({ headerShown: true, title: "Funding Account" }),
  },
  {
    name: "Help",
    component: HelpScreen,
    appearsInDrawer: true,
    options: () => ({ headerShown: true, title: "Help" }),
  },
  {
    name: "PrivacyPolicy",
    component: PrivacyPolicyScreen,
    appearsInDrawer: true,
    options: () => ({ headerShown: true, title: "Privacy Policy" }),
  },
  {
    name: "Terms",
    component: TermsScreen,
    appearsInDrawer: true,
    options: () => ({ headerShown: true, title: "T&C's" }),
  },
  {
    name: "Profile",
    component: ProfileScreen,
    appearsInDrawer: true,
    options: () => ({ headerShown: true, title: "Profile" }),
  },
  {
    name: "Transactions",
    component: TransactionsScreen,
    options: () => ({ headerShown: true, title: "Transactions" }),
  },
  {
    name: "PayNow",
    component: PayNowScreen,
    options: () => ({ headerShown: true, title: "Paynow" }),
  },
  {
    name: "LinkedSavingsAccount",
    component: LinkedSavingsAccountScreen,
    appearsInDrawer: false,
    options: () => ({
      headerShown: false,
      title: "Linked Savings Account",
    }),
  },
];

const AuthenticatedDrawerStackRoot =
  createDrawerNavigator<AuthenticatedRootStackParamList>();

type AuthenticatedStackProps = {
  name?: string;
};
export const AuthenticatedStack: FunctionComponent<
  AuthenticatedStackProps
> = () => {
  const { width } = useWindowDimensions();
  const [availableWidth, setAvailableWidth] = useState(width);

  useEffect(() => {
    setAvailableWidth(width);
  }, [width]);

  const WebCompatibleDrawerWidth =
    Platform.select({
      // here be magic...
      // long story short, accounting for the fact that react navigation drawer navigators float
      // off screen to the left by design makes calculating available space hard.
      // eslint-disable-next-line no-nested-ternary
      web: hasWidthAvailableForAppAndSidebar(availableWidth)
        ? requiresLeftAnchor(availableWidth)
          ? WEB_SIDEBAR_WIDTH + 8
          : WEB_SIDEBAR_WIDTH + 8
        : WEB_SIDEBAR_WIDTH + 100,
    }) ?? WEB_SIDEBAR_WIDTH + 8;

  const headerStyle =
    Platform.select({
      web: { height: WEB_HEADER_HEIGHT },
    }) ?? {};

  const drawerStyle =
    Platform.select({
      web: {
        width: WebCompatibleDrawerWidth,
      },
    }) ?? {};

  return (
    <AuthenticatedDrawerStackRoot.Navigator
      initialRouteName="Dashboard"
      screenOptions={({ navigation }) => ({
        headerStyle,
        headerShown: true,
        headerLeft: () =>
          Platform.select({
            ios: <MenuButton navigation={navigation} />,
            android: <MenuButton navigation={navigation} />,
            web: hasWidthAvailableForAppAndSidebar(
              availableWidth
            ) ? undefined : (
              <MenuButton navigation={navigation} />
            ),
          }),
      })}
      drawerContent={(props) => {
        return (
          <DrawerWithLogoutButton
            {...props}
            items={authenticatedScreenList.filter(
              (screen) => screen.appearsInDrawer
            )}
          />
        );
      }}
      drawerStyle={drawerStyle}
    >
      {authenticatedScreenList.map(({ name, component, options }, index) => (
        <AuthenticatedDrawerStackRoot.Screen
          key={index}
          name={name as keyof AuthenticatedRootStackParamList}
          component={component}
          options={options}
        />
      ))}
    </AuthenticatedDrawerStackRoot.Navigator>
  );
};
