import React from "react";
import { Text, View } from "react-native";
import { t } from "react-native-tailwindcss";
import Box from "../../../atoms/Box";
import Card from "../../../atoms/Card";
import { BankAccount } from "../../../../services/bankService";
import { HStack } from "../../../layout/HStack";
import { VStack } from "../../../layout/VStack";

export const FundingAccount = ({
  account,
  checked,
  updateAccount,
  valid = true,
}: {
  account: BankAccount;
  checked: boolean;
  updateAccount: (accountId: string) => void;
  valid?: boolean;
}): React.ReactElement => {
  const setFundingAccount = async () => {
    if (!checked) {
      updateAccount(account._id);
    }
  };

  return (
    <HStack style={[valid ? t.opacity100 : t.opacity25]}>
      <Card style={styles.item} key={account.id}>
        <View style={t.wFull}>
          <Text style={{ fontSize: 12, fontWeight: '500' }}>{account.name}   {account.accountNumber}</Text>
          <Text style={[t.pB2, { fontSize: 11 }]}>
            Account Type:{" "}
            <Text style={{ textTransform: "capitalize" }}>
              {account.accountType}
            </Text>
          </Text>
          {/* <Text style={t.mB2}>Account Number: {account.accountNumber}</Text> */}
        </View>
      </Card>
      <HStack style={{ width: 50, marginLeft: 20 }}>
        <VStack>
          <View style={t.mB2}>
            <Box
              disabled={!valid}
              checked={checked}
              onPress={() => setFundingAccount()}
            />
          </View>
        </VStack>
      </HStack>
    </HStack>
  );
};

const styles = {
  container: [t.flex1, t.p6, t.bgGray200],
  heading: [t.mY8, t.textCenter],
  itemContainer: [t.flex, t.flexCol, t.flex1],
  item: [t.w5_6, t.pB3, t.pT0, t.flex, t.flexRow],
  logo: [t.mR6],
};
