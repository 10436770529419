import {
  NavigationProp,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Platform,
  StyleProp,
  useWindowDimensions,
  ViewProps,
  ViewStyle,
} from "react-native";
import styled from "styled-components/native";
import { getScreens } from "../../navigation/shared";
import {
  calculateAppDisplayWidthOnWeb,
  WEB_HEADER_HEIGHT,
} from "../../web-helper";
import { HCenterStack } from "./HStack";
import { VStack, VCenterStack } from "./VStack";
import { appConfig } from "../../config/config";

type ContentContainerProps = {
  center?: boolean;
  platformSpecificHeaderCompatibleHeight: string;
  platformSpecificWidth: string;
  padding?: number;
};

type Props = {
  children: any;
  center?: boolean;
  backgroundColor?: string;
  padding?: number;
  style?: StyleProp<ViewStyle>;
};
// Todo : Need to find a proper ways to get the full height of the window
// For the moment manually add extra 600px to fix the background issue
const ContentContainer = styled.View<ContentContainerProps>`
  display: flex;
  flex-direction: column;
  height: ${(props) => props.platformSpecificHeaderCompatibleHeight + 600};
  width: ${(props) => props.platformSpecificWidth};
  justify-content: ${(props) => (props.center ? "center" : "flex-start")};
  padding: ${(props) => (props.padding ? (props.padding + "px") : "20px")};
`;

type ScreenContainerProps = {} & Props;
export const ScreenContainer: FunctionComponent<ScreenContainerProps> = (
  props: Props
) => {
  const { width, height } = useWindowDimensions();
  const [availableWidth, setAvailableWidth] = useState(width);
  const route = useRoute();
  const navigation = useNavigation<NavigationProp<any>>();

  useEffect(() => {
    setAvailableWidth(width);
  }, [width]);

  const platformSpecificWidth =
    Platform.select({
      web: `${calculateAppDisplayWidthOnWeb(availableWidth)}px`,
    }) ?? `${width}px`;

  // this is awful. all we're trying to do is on web, account for the header height when setting the container
  // height so that it doesn't show a scrollbar when the header is showing. but for whatever reason, react navigation doesn't provide
  // a simple api to check if the header is showing or not. it doesn't seem to require this on mobile
  const getWebHeight = () => {
    const myScreen = getScreens().find((s) => s.name === route?.name);
    const headerShown = myScreen?.options(navigation).headerShown ?? false;
    return `${height - (headerShown ? WEB_HEADER_HEIGHT : 0)}px`;
  };

  const platformSpecificHeaderCompatibleHeight =
    Platform.select({ web: getWebHeight() }) ?? `${height}px`;

  const backgroundColor = props.backgroundColor ?? appConfig.dashboardBackground;
  const VerticalPositionedContainer = props.center ? VCenterStack : VStack;
  console.log(props.style);

  return (
    <VerticalPositionedContainer style={[{ backgroundColor }, props.style]}>
      <HCenterStack>
        <ContentContainer
          platformSpecificWidth={platformSpecificWidth}
          platformSpecificHeaderCompatibleHeight={
            platformSpecificHeaderCompatibleHeight
          }
          padding={props.padding}
          center={props.center}
        >
          {props.children}
        </ContentContainer>
      </HCenterStack>
    </VerticalPositionedContainer>
  );
};
