import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import { ApiError } from "../../services/api/api";
import { QueryKey } from "../../services/api/query";
import { payFixedAmount } from "../../services/paymentService";

export const usePayFixedAmountMutation = (): UseMutationResult<
  void,
  ApiError,
  number
> => {
  const queryClient = useQueryClient();

  return useMutation<void, ApiError, number>(amount => payFixedAmount(amount), {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKey.User);
      queryClient.invalidateQueries(QueryKey.Payments);
    },
  });
};
