import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

import { CriticalErrorScreen } from "../../components/screens/Error/CriticalError/CriticalErrorScreen";

const ErrorStackRoot = createStackNavigator<ErrorRootStackParamList>();

export const ErrorStack = () => (
  <ErrorStackRoot.Navigator
    initialRouteName="ErrorScreen"
    screenOptions={{ headerShown: false }}
  >
    <ErrorStackRoot.Screen
      name={"ErrorScreen"}
      component={CriticalErrorScreen}
      options={{ title: "Welcome" }}
    />
  </ErrorStackRoot.Navigator>
);
