import {
  getAuth,
  onAuthStateChanged,
  User as FirebaseUser,
} from "firebase/auth";
import React, { ReactNode, useContext, useState } from "react";
import { useQueryClient } from "react-query";
import { firebaseApp } from "../config/firebase";
import { QueryKey } from "../services/api/query";

type AuthContext = {
  authenticated?: boolean;
};

const AuthContext = React.createContext<AuthContext>({} as AuthContext);

const AuthProvider = ({
  children,
}: {
  children: ReactNode;
}): React.ReactElement => {
  const queryClient = useQueryClient();
  const [authenticated, setAuthenticated] = useState<boolean | undefined>();
  const auth = getAuth(firebaseApp);
  onAuthStateChanged(auth, (u: FirebaseUser | null) => {
    if (u?.emailVerified) {
      queryClient.invalidateQueries(QueryKey.User);
      queryClient.invalidateQueries(QueryKey.Club);
      queryClient.invalidateQueries(QueryKey.Accounts);
    }
    setAuthenticated(u?.emailVerified ?? false);
  });
  return (
    <AuthContext.Provider
      value={{
        authenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = (): AuthContext => useContext(AuthContext);
export { AuthProvider, useAuth };
