import React, { useEffect, useState } from "react";
import { View, ActivityIndicator } from "react-native";
import { useMutation, useQueryClient } from "react-query";
import { t } from "react-native-tailwindcss";
import { HCenterStack } from "../../../layout/HStack";
import AccordionCard from '../../../organisms/AccordionCard';
import { QueryKey } from "../../../../services/api/query";
import { useUser } from '../../../../context/userContext';
import styled from 'styled-components/native';
import Box from '../../../atoms/Box';
import { LineTitle } from "../../../molecules/LineTitle";
import { setWeeklyMaximumLimit } from '../../../../services/userService';
import { CustomErrors } from "../../Auth/Registration/RegistrationScreen"

const WeeklyMaximumAmountSelectorContainer = styled(HCenterStack)`
  width: 100%;
  padding-bottom: 15px;
`;

type WeeklyMaximumLimitProps = {
    setCustomErrors: (error?: any) => void;
};

export const WeeklyMaximumLimit = ({
    setCustomErrors,
}: WeeklyMaximumLimitProps): React.ReactElement => {
    const { user } = useUser();
    const queryClient = useQueryClient();
    const [weeklyLimitAmtCents, setWeeklyLimitAmtCents] = useState<number>(
        user?.weeklyMaximumLimit ?? 0,
    );

    useEffect(() => {
        setWeeklyLimitAmtCents(user?.weeklyMaximumLimit ?? 0);
    }, [user]);

    const mutation = useMutation(setWeeklyMaximumLimit, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(QueryKey.UpdateWeeklyMaximumLimit);
        },
        onError: (error: any) => {
            setCustomErrors(error);
        },
    });

    const updateWeeklyMaximumLimit = async (weeklyLimit: number) => {
        setCustomErrors();
        mutation.mutate(weeklyLimit);
    };

    const updateDataForWeeklyLimit = (amount: number) => {
        setWeeklyLimitAmtCents(amount);
        updateWeeklyMaximumLimit(amount);
    }

    const weeklyLimitAmtList: { title: string, value: number }[] = [
        {
            title: '$7.50',
            value: 750,
        },
        {
            title: '$10.00',
            value: 1000,
        },
        {
            title: '$12.50',
            value: 1250,
        },
    ]

    const toggleSwitch = async () => {
        if (weeklyLimitAmtCents > 0) {
            updateDataForWeeklyLimit(0);
        } else {
            updateDataForWeeklyLimit(weeklyLimitAmtList[0].value);
        }
    };

    return (
        <AccordionCard
            checked={!!weeklyLimitAmtCents}
            toggleSwitch={toggleSwitch}
            style={[t.pX0, t.pB1, t.bgWhite]}
            title={`Weekly Maximum ${weeklyLimitAmtCents ? 'on' : 'off'}`}
            isDisabled={mutation.isLoading}
        >
            <View style={t.mT4}>
                <LineTitle title="Limit round-up amount per week" />
            </View>
            {mutation.isLoading ?
                <ActivityIndicator color={'#FFC829'} />
                :
                <WeeklyMaximumAmountSelectorContainer>
                    {weeklyLimitAmtList.map((b, i) => (
                        <Box
                            customContainerStyles={{
                                backgroundColor: "none",
                                borderWidth: 0,
                            }}
                            title={b.title}
                            checked={b.value === weeklyLimitAmtCents}
                            onPress={() => {
                                updateDataForWeeklyLimit(b.value);
                            }}
                            key={i.toString()}
                        />
                    ))}
                </WeeklyMaximumAmountSelectorContainer>
            }
        </AccordionCard>
    );
};