import { getAuth, getIdToken, onAuthStateChanged } from "firebase/auth";

import { firebaseApp } from "../../config/firebase";

export const getUserToken = () =>
  new Promise((resolve) => {
    onAuthStateChanged(getAuth(firebaseApp), async (user) => {
      if (user && user.emailVerified) {
        const token = await getIdToken(user);

        resolve(token);
        return;
      }
      resolve(null);
    });
  });
