// import React from "react";
// import { NavigationProp, useNavigation } from "@react-navigation/native";
// import { useToast } from "react-native-toast-notifications";
// import styled from "styled-components/native";
// import { useQuery } from "react-query";

// import { ScreenContainer } from "../../../layout/ScreenContainer";
// import { SetupScreensParamList } from "../../../../navigation/setup-screens";
// import { Footer } from "../../../molecules/Footer";
// import {
//   ApiError,
//   convertErrorCodeToErrorMessage,
//   ErrorCode,
// } from "../../../../services/api/api";
// import { syncBasiqAccountsJob } from "../../../../services/bankService";
// import { QueryKey } from "../../../../services/api/query";
// import { Job, JobStatus } from "../../../../dtos/job";
// import { getJob } from "../../../../services/jobService";
// import { JobFeedback2 } from "../../../organisms/JobFeedback2";
// import { LargeText } from "../../../atoms/LargeText";

// const StyledFooterContainer = styled.View`
//   margin-top: 30px;
// `;

// export type BankLoginFormData = {
//   institutionSlug: string;
//   username: string;
//   password: string;
// };

// export const ConnectBankScreen = (): React.ReactElement => {
//   const { data: bankAccountSyncData, error: accountSyncError } = useQuery<
//     Job | null,
//     ApiError
//   >({
//     queryKey: [QueryKey.SyncBasiqBankAccountsSync],
//     queryFn: () => syncBasiqAccountsJob(),
//   });

//   const { data: jobStatusData, error: jobStatusError } = useQuery<
//     Job | null,
//     ApiError
//   >({
//     queryKey: [QueryKey.Jobs],
//     queryFn: () => getJob(bankAccountSyncData?._id),
//     enabled: !!bankAccountSyncData,
//     refetchInterval: (data) => {
//       return data && data?.status !== JobStatus.Success ? 1000 : false;
//     },
//   });

//   const navigation = useNavigation<NavigationProp<SetupScreensParamList>>();
//   const toast = useToast();

//   if (accountSyncError && accountSyncError?.message) {
//     toast.show(convertErrorCodeToErrorMessage(ErrorCode.bankConnectionError), {
//       type: "danger",
//     });
//   }

//   if (jobStatusError && jobStatusError?.message) {
//     toast.show(convertErrorCodeToErrorMessage(ErrorCode.bankConnectionError), {
//       type: "danger",
//     });
//   }

//   if (jobStatusData?.status === JobStatus.Success) {
//     navigation.navigate("RoundupAccountsSetup");
//   }

//   return (
//     <ScreenContainer>
//       <LargeText center>Setting Funding Account</LargeText>
//       <JobFeedback2
//         steps={[
//           {
//             title: "Retrieving Accounts",
//             status: jobStatusData?.status || JobStatus.Pending,
//           },
//         ]}
//         description="We are working on connecting your account. This could take a minute."
//       />
//       <StyledFooterContainer>
//         <Footer />
//       </StyledFooterContainer>
//     </ScreenContainer>
//   );
// };

import React, { useEffect, useState } from "react";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { useToast } from "react-native-toast-notifications";
import styled from "styled-components/native";

import { View } from "react-native";
import { HCenterStack } from "../../../layout/HStack";

import { t } from "react-native-tailwindcss";
import { ScreenContainer } from "../../../layout/ScreenContainer";
import { SetupScreensParamList } from "../../../../navigation/setup-screens";
import { Footer } from "../../../molecules/Footer";
import {
  ApiError,
  convertErrorCodeToErrorMessage,
} from "../../../../services/api/api";
import { syncBasiqAccountsJob } from "../../../../services/bankService";
import { queryClient, QueryKey } from "../../../../services/api/query";
import { JobStatus } from "../../../../dtos/job";
import { LargeText } from "../../../atoms/LargeText";
import { useAccounts } from "../../../../context/accountsContext";
import { useJob } from "../../../../hooks/useJob";
import { useError } from "../../../../context/errorContext";
import { JobFeedback } from "../../../organisms/JobFeedback";
import Button from "../../../atoms/Button";
import { updateUserConsent } from "../../../../services/userService";
import { useUser } from "../../../../context/userContext";
import { MediumText } from "../../../atoms/MediumText";
import ClientLogo from "../../../atoms/ClientLogo";
import { ScrollView } from "react-native-gesture-handler";
import { authStyles } from "../../../screens/Auth/shared";
import { appConfig } from "../../../../config/config";

const StyledFooterContainer = styled.View`
  margin-top: 50px;
`;

export type BankLoginFormData = {
  institutionSlug: string;
  username: string;
  password: string;
};

export const ConnectBankScreen = (): React.ReactElement => {
  const navigation = useNavigation<NavigationProp<SetupScreensParamList>>();

  const syncBasiqJobContext = useJob(syncBasiqAccountsJob);
  const toast = useToast();

  const { setCriticalError } = useError();
  const { accounts } = useAccounts();
  const { user } = useUser();

  const [loading, setLoading] = useState<boolean>(false);
  const [polling, setPolling] = useState<boolean>(false);

  const fetchAccounts = async () => {
    setLoading(true);
    try {
      const response = await updateUserConsent(true);
      if (response && !response.errors) {
        await syncBasiqJobContext.execute();
        queryClient.invalidateQueries(QueryKey.Accounts);
        navigation.navigate("RoundupAccountsSetup");
      }
    } catch (e: (Error & ApiError) | any) {
      setLoading(false);
      if (e.message) {
        toast.show(convertErrorCodeToErrorMessage(e.message), {
          type: "danger",
        });
        return;
      }

      toast.show("Something went wrong", {
        type: "danger",
      });
      setCriticalError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setPolling(loading || syncBasiqJobContext.status === JobStatus.Pending);
  }, [loading, syncBasiqJobContext.status]);

  // useEffect(() => {
  //   if (accounts && accounts.length > 0 && user && user?.isConsent) {
  //     navigation.navigate("RoundupAccountsSetup");
  //   }
  // }, [accounts, navigation, user]);

  // if (accounts && accounts.length > 0 && user?.isConsent) {
  //   navigation.navigate("RoundupAccountsSetup");
  // }

  return (
    <ScrollView style={{ backgroundColor: appConfig.dashboardBackground }}>
      <ScreenContainer center>
        <HCenterStack style={authStyles.imageContainer}>
          <View style={{ width: "70%" }}>
            <ClientLogo />
          </View>
        </HCenterStack>
        <LargeText center style={{ fontSize: 25 }}>Success! You Have Connected</LargeText>
        <HCenterStack>
          <MediumText>You have successfully connected to your bank.</MediumText>
        </HCenterStack>
        <HCenterStack>
          <MediumText>Next step is to setting up your bank accounts</MediumText>
        </HCenterStack>
        {polling ? (
          <>
            {/* <LargeText center>Setting up Accounts</LargeText> */}

            <JobFeedback
              steps={[
                { title: "Retrieving Accounts", context: syncBasiqJobContext },
              ]}
              description="We are working on connecting your accounts. This could take a minute."
            />
          </>
        ) : (
          <></>
        )}
        <View style={styles.buttonContainer}>
          <Button
            disabled={loading || polling}
            label="Select Your Accounts"
            onPress={() => {
              fetchAccounts();
            }}
          />
        </View>
      </ScreenContainer>
      <StyledFooterContainer>
        <Footer />
      </StyledFooterContainer>
    </ScrollView>
  );
};
const styles = {
  buttonContainer: [{ marginTop: 20 }, t.pL10, t.pR10],
};
