import React, { useState } from "react";
import { ScrollView } from "react-native-gesture-handler";
import { View, ActivityIndicator } from "react-native";
import { t } from "react-native-tailwindcss";
import { useToast } from "react-native-toast-notifications";
import { useQueryClient } from "react-query";
import { ScreenContainer } from "../../../layout/ScreenContainer";
import { FundingAccount } from "./FundingAccount";
import { MediumText } from "../../../atoms/MediumText";
import { ZeptoTerms } from "../../../molecules/ZeptoTerms";
import { ApiError } from "../../../../services/api/api";
import { useAccounts } from "../../../../context/accountsContext";
import { LargeText } from "../../../atoms/LargeText";
import { JobFeedback } from "../../../organisms/JobFeedback";
import { setBasiqDebitAccountJob } from "../../../../services/userService";
import { useError } from "../../../../context/errorContext";
import { useJob } from "../../../../hooks/useJob";
import { QueryKey } from "../../../../services/api/query";

export const FundingAccountScreen = (): React.ReactElement => {
  const [polling, setPolling] = useState<boolean>(false);
  const setDebitAccountJobContext = useJob(setBasiqDebitAccountJob);
  const queryClient = useQueryClient();
  const { setCriticalError } = useError();

  const { accounts, isAccountsLoading } = useAccounts();
  const toast = useToast();

  const updateAccount = async (accountId: string) => {
    try {
      setPolling(true);
      await setDebitAccountJobContext.execute(accountId);
      queryClient.invalidateQueries(QueryKey.User);
      queryClient.invalidateQueries(QueryKey.Accounts);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    } catch (error: Error & ApiError) {
      toast.show("Something went wrong", {
        type: "danger",
      });
      setCriticalError(error);
    } finally {
      setPolling(false);
    }
  };

  return (
    <ScreenContainer>
      {polling ? (
        <>
          <LargeText center>Setting Funding Account</LargeText>

          <JobFeedback
            steps={[
              {
                title: "Configuring your debiting agreement",
                context: setDebitAccountJobContext,
              },
            ]}
            description="We are requesting permission to debit your account. This could take a minute."
          />
        </>
      ) : (
        <>
          <ScrollView contentContainerStyle={styles.itemContainer}>
            <MediumText>
              The selected account will have round-up totals debited from every
              Monday, Wednesday and Friday. You can change this or turn off
              round-ups at any time.
            </MediumText>
            <ZeptoTerms />
            {isAccountsLoading ?
              <View style={{ justifyContent: 'center', height: (60 * (accounts?.length ?? 1)) }}>
                <ActivityIndicator size={'large'} color={'#FFC829'} />
              </View>
              :
              <View style={{ paddingTop: 30, paddingBottom: 5 }}>
                {accounts?.map((account, i) => (
                  <FundingAccount
                    valid={["transaction", "savings"].includes(
                      account.accountType
                    )}
                    account={account}
                    key={i.toString()}
                    checked={account.activeDebitEnabled}
                    updateAccount={updateAccount}
                  />
                ))}
              </View>
            }
          </ScrollView>
        </>
      )}
    </ScreenContainer>
  );
};
const styles = {
  heading: [t.mY8, t.textCenter],
  itemContainer: [t.flex, t.flexCol, t.flex1, { height: "70%" }],
  item: [t.wFull, t.p3, t.mB3],
};
