import React from "react";
import { View, Text } from "react-native";
import { t } from "react-native-tailwindcss";

export const LineTitle = ({
  title,
  bold = true,
}: {
  title: string;
  bold?: boolean;
}): React.ReactElement => {
  return (
    <View style={[t.mB5, { flexDirection: "row", alignItems: "center" }]}>
      <View style={{ flex: 1, height: 1, backgroundColor: "black" }} />
      <View>
        <Text
          style={{
            marginLeft: 15,
            marginRight: 15,
            textAlign: "center",
            fontWeight: bold ? "600" : undefined,
          }}
        >
          {title}
        </Text>
      </View>
      <View style={{ flex: 1, height: 1, backgroundColor: "black" }} />
    </View>
  );
};
