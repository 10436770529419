import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { t } from "react-native-tailwindcss";
import { useToast } from "react-native-toast-notifications";
import Currency from "../../../atoms/Currency";
import { Transaction } from "../../../../dtos/transaction";
import { ScreenContainer } from "../../../layout/ScreenContainer";
import { VStack } from "../../../layout/VStack";
import { MediumText } from "../../../atoms/MediumText";
import { getTransactions } from "../../../../services/bankService";
import { getErrorMessage } from "../../../../services/api/api";
import { Spinner } from "../../../organisms/Spinner";

const TransactionRow = ({
  transaction,
}: {
  transaction: Transaction;
}): React.ReactElement => {
  const displayPaidAmount = () => {
    return transaction.paidAmountCents === undefined ||
      transaction.paidAmountCents === null
      ? transaction.debitAmountCents
      : transaction.paidAmountCents;
  };

  return (
    <View style={styles.rowContainer}>
      <Text>{transaction.description}</Text>
      <View style={styles.currency}>
        <Currency cents={transaction.amountCents} />
      </View>
      <View style={styles.currency}>
        <Currency isBold cents={displayPaidAmount()} />
      </View>
    </View>
  );
};

export const TransactionsScreen = (): React.ReactElement => {
  const [transactions, setTransactions] = useState<Transaction[]>();
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const transactionsResponse = await getTransactions();
        // console.log({ transactionsResponse });
        const orderedTransactions = (transactionsResponse || []).sort(
          (a, b) => {
            return `${b.date}`.localeCompare(a.date);
          }
        );
        setTransactions(orderedTransactions);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
      } catch (error: Error & ApiError) {
        console.log(getErrorMessage(error));
        toast.show("Something went wrong", {
          type: "danger",
        });
      } finally {
        setLoading(false);
      }
    })();
  }, [toast]);

  const renderRows = () => {
    const elements = [];
    console.log(transactions);
    if (!transactions?.length) {
      elements.push(
        <MediumText center style={t.pT6}>
          You have no transactions. It may be that they haven't synced yet, this
          process may take up to a day.
        </MediumText>
      );
    } else {
      let title = "";
      for (let i = 0; i < transactions.length; i += 1) {
        const transaction = transactions[i];
        const formattedDate = format(new Date(transaction.date), "EE dd MMM");
        if (title !== formattedDate) {
          elements.push(
            <MediumText style={[t.pB2, t.pT6, t.fontBold]}>
              {formattedDate}
            </MediumText>
          );
          title = formattedDate;
        }
        elements.push(
          <TransactionRow key={i.toString()} transaction={transaction} />
        );
      }
    }
    console.log(elements);
    return elements;
  };

  return (
    <ScreenContainer>
      {loading ? (
        <Spinner />
      ) : (
        <VStack>
          <ScrollView contentContainerStyle={{ height: "80%" }}>
            {renderRows()}
          </ScrollView>
        </VStack>
      )}
    </ScreenContainer>
  );
};

const styles = {
  dayContainer: [t.pY3],
  currency: [t.pX1],
  rowContainer: [
    t.flexRow,
    t.justifyBetween,
    { borderBottomWidth: StyleSheet.hairlineWidth },
    t.p4,
  ],
};
