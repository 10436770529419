import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

import { LoginScreen } from "../../components/screens/Auth/Login/LoginScreen";
import { RegistrationScreen } from "../../components/screens/Auth/Registration/RegistrationScreen";
import { HelpScreen } from "../../components/screens/Shared/Help/HelpScreen";
import { PrivacyPolicyScreen } from "../../components/screens/Shared/PrivacyPolicy/PrivacyPolicyScreen";
import { TermsScreen } from "../../components/screens/Shared/Terms/TermsScreen";
import { ForgotPasswordScreen } from "../../components/screens/Auth/ForgotPassword/ForgotPasswordScreen";
import { EmailVerifyScreen } from "../../components/screens/Auth/VerifyEmail/EmailVerifyScreen";


const unauthenticatedScreenList: IScreenRoute[] = [
  { name: "Login", component: LoginScreen, options: { title: "Login" } },
  {
    name: "ForgotPassword",
    component: ForgotPasswordScreen,
    options: { title: "Forgot Password?" },
  },
  {
    name: "Registration",
    component: RegistrationScreen,
    options: { title: "Register" },
  },
  { name: "Help", component: HelpScreen, options: { title: "Help" } },
  {
    name: "PrivacyPolicy",
    component: PrivacyPolicyScreen,
    options: { title: "Privacy Policy" },
  },
  {
    name: "Terms",
    component: TermsScreen,
    options: { title: "Terms & Conditions" },
  },
  {
    name: "VerifyEmail",
    component: EmailVerifyScreen,
    options: { title: "Verify Email" },
  },
];

const UnauthenticatedStackRoot =
  createStackNavigator<UnauthenticatedRootStackParamList>();

export const UnauthenticatedStack = () => (
  <UnauthenticatedStackRoot.Navigator
    initialRouteName="Login"
    screenOptions={{ headerShown: false }}
  >
    {unauthenticatedScreenList.map(({ name, component, options }, index) => (
      <UnauthenticatedStackRoot.Screen
        key={index}
        name={name as keyof UnauthenticatedRootStackParamList}
        component={component}
        options={options}
      />
    ))}
  </UnauthenticatedStackRoot.Navigator>
);
