import React from "react";
import { View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { appConfig } from "../../../../config/config";

import { ScreenContainer } from "../../../layout/ScreenContainer";
import { MonthlyPayments } from "./MonthlyPayments";
import { RoundupAmounts } from "./RoundupAmounts";
import { TotalAmounts } from "./TotalAmounts";
import { LinkedSavingsAccount } from "./LinkedSavingsAccount";
import { Statements } from "./Statements";
import { useQuery } from "react-query";
import { LinkAccount } from "../../../../dtos/job";
import { QueryKey } from "../../../../services/api/query";
import { getLinkedSavingsAccount } from "../../../../services/userService";
import { useToast } from "react-native-toast-notifications";
import { DonateNowComponenet } from "../DonateWidget/DonateNowComponent";

export const DashboardScreen = (): React.ReactElement => {
  const toast = useToast();

  const { data: linkedData, isFetching } = useQuery<LinkAccount>(
    [QueryKey.LinkedSavingAccount],
    () => getLinkedSavingsAccount(),
    {
      onError: (error: any) => {
        if (error) {
          toast.show(error.message, {
            type: "danger",
          });
        }
      },
    }
  );
  return (
    <ScrollView style={{ backgroundColor: appConfig.dashboardBackground }}>
      <View style={{
        backgroundColor: appConfig.primaryColour,
        height: '35%',
        width: '100%',
        position: 'absolute',
      }}>
      </View>
      <ScreenContainer style={{ backgroundColor: 'transparent' }} padding={25}>
        <TotalAmounts />
        <RoundupAmounts />
        {/* <LinkedSavingsAccount linkedData={linkedData} isFetching={isFetching} /> */}
        <DonateNowComponenet />
        <MonthlyPayments />
      </ScreenContainer>
    </ScrollView>
  );
};
