import React from "react";
import { Linking, View } from "react-native";
import { LinkText } from "../atoms/LinkText";
import { MediumText } from "../atoms/MediumText";

export const ZeptoTerms = (): React.ReactElement => (
  <>
    <MediumText>
      By selecting a funding account, you are agreeing to Zepto's terms and
      conditions which can be found here:{"\n \n"}
      <LinkText
        onPress={() => Linking.openURL("https://www.zepto.com.au/ddr/")}
      >
        Zepto Direct Debit Request{"\n"}
      </LinkText>
      <LinkText
        onPress={() => Linking.openURL("https://www.zepto.com.au/ddrsa/")}
      >
        Zepto Direct Debit Request Service Agreement{" "}
      </LinkText>
    </MediumText>
  </>
);
