/* eslint-disable no-irregular-whitespace */
import React from 'react';
import { t } from 'react-native-tailwindcss';
import Unorderedlist from 'react-native-unordered-list';
import { Linking } from 'react-native';
import { MediumText } from '../../../atoms/MediumText';
import { LinkText } from '../../../atoms/LinkText';
import { LargeText } from '../../../atoms/LargeText';

export const SiporaPrivacyPolicy = (): React.ReactElement => {
  return (
    <>
      <MediumText>
        This privacy statement sets out how Sipora Pty Ltd ACN 612 558 979
        (“Sipora”) will manage your personal information and other information.
      </MediumText>
      <MediumText>
        In this privacy policy “your information” means your personal
        information, as defined under the 
        <MediumText style={t.italic}>Privacy Act 1988 (Cth)</MediumText>
         (“Privacy Act”). Although we do not allow children to use our apps, in
        the event we knowingly collect information from a child, for parents,
        your child’s information will be treated in the same manner as “your
        information” is treated throughout this Privacy Policy.
      </MediumText>

      <LargeText style={t.fontBold}>Information collected by Sipora</LargeText>
      <MediumText>
        Depending on the particular circumstances, Sipora may collect and hold a
        range of different information about you. This can include your name,
        date of birth, contact details (including address, email address, phone
        number or mobile telephone number), username or password for our website
        or apps, username or password for your online banking account(s),
        financial information (such as credit card or bank account numbers) and
        information about how you use Sipora’s products and services.
      </MediumText>
      <MediumText>
        It is not intended that Sipora will directly collect, store or use your
        username or password for your online banking account(s), or your
        financial information (such as credit card or bank account numbers).
        Rather, it is intended that those details will be collected, stored and
        used by a third party provider that Sipora will use to facilitate the
        use of its website and apps.
      </MediumText>
      <MediumText>
        If personal information concerns particular beliefs or backgrounds, it
        is considered to be of a sensitive nature. Such information may include:
      </MediumText>
      <Unorderedlist>
        <MediumText>racial background;</MediumText>
      </Unorderedlist>
      <Unorderedlist>
        <MediumText>
          political opinions and political party memberships;
        </MediumText>
      </Unorderedlist>
      <Unorderedlist>
        <MediumText>religious or philosophical beliefs;</MediumText>
      </Unorderedlist>
      <Unorderedlist>
        <MediumText>trade union affiliations or membership;</MediumText>
      </Unorderedlist>
      <Unorderedlist>
        <MediumText>sexual preferences;</MediumText>
      </Unorderedlist>
      <Unorderedlist>
        <MediumText>criminal record; or</MediumText>
      </Unorderedlist>
      <Unorderedlist>
        <MediumText>health information.</MediumText>
      </Unorderedlist>
      <MediumText>Sipora does not collect sensitive information.</MediumText>

      <LargeText style={t.fontBold}>
        How Sipora will collect your information
      </LargeText>

      <MediumText>
        Sipora may collect your information in a number of ways, including:
      </MediumText>
      <Unorderedlist>
        <MediumText>
          directly from you (such as when you obtain a subscription for our
        </MediumText>
      </Unorderedlist>
      <Unorderedlist>
        <MediumText>
          apps, where you provide information to Sipora, when you purchase
          products or services from Sipora or visit Sipora’s website or
          contact Sipora with a query or request)
        </MediumText>
      </Unorderedlist>
      <Unorderedlist>
        <MediumText>from publicly available sources of information;</MediumText>
      </Unorderedlist>
      <Unorderedlist>
        <MediumText>
          with your consent, from third parties and your financial institution;
        </MediumText>
      </Unorderedlist>
      <Unorderedlist>
        <MediumText>
          from Sipora’s records of how you use Sipora’s products or services
        </MediumText>
      </Unorderedlist>

      <MediumText>
        If you choose not to provide certain information about
        yourself, Sipora may not be able to provide you with the products,
        services or information you require.
      </MediumText>

      <LargeText style={t.fontBold}>
        How Sipora will hold your information
      </LargeText>

      <MediumText>
        Sipora may store your information in hard copy or electronic format, in
        storage facilities that Sipora owns, or that are owned and operated by
        third party service providers.
      </MediumText>

      <MediumText>
        Sipora’s third party providers may store information they collect in
        hard copy or electronic format, in storage facilities that Sipora owns,
        or that are owned and operated by other providers.
      </MediumText>

      <MediumText>
        Sipora takes the privacy and security of your information seriously and
        Sipora is committed to maintaining the security of your information
        under Sipora’s control.
      </MediumText>

      <MediumText>
        Sipora uses a combination of technical solutions, security controls and
        internal processes to protect your information from unauthorised access
        and disclosure.
      </MediumText>

      <MediumText>
        While Sipora takes these steps to maintain the security of your
        information, you should be aware of the range of information security
        risks that exist today and take appropriate care to help safeguard your
        own information.
      </MediumText>

      <LargeText style={t.fontBold}>
        Purposes for which Sipora will collect, hold and use your information
      </LargeText>

      <MediumText>
        Sipora or third party providers may collect, hold and use your
        information for a range of different purposes, including
      </MediumText>

      <Unorderedlist>
        <MediumText>
          to provide products and services to you, to provide you with
          information about those products and services, to assist you with
          enquiries or purchases, to enhance your experience on Sipora’s website
          or apps, or to provide you with better customer service;
        </MediumText>
      </Unorderedlist>
      <Unorderedlist>
        <MediumText>
          to administer and manage the products and services Sipora provides to
          you, to charge and bill you for them, and to collect any amounts you
          may owe Sipora; and
        </MediumText>
      </Unorderedlist>
      <Unorderedlist>
        <MediumText>
          to improve or develop products and services, or to perform research
          and analysis.
        </MediumText>
      </Unorderedlist>

      <MediumText>
        Sipora does not allow children to use our apps. For this reason, Sipora
        may monitor visitors to Sipora’s website or users of our apps. We will
        not collect personal information that would be unlawful, unnecessary or
        unrelated to our activities or business.
      </MediumText>

      <LargeText style={t.fontBold}>
        When Sipora will disclose your information
      </LargeText>

      <MediumText>Sipora may disclose your information:</MediumText>

      <Unorderedlist>
        <MediumText>
          as required or authorised by law, including to law enforcement and
          national security agencies, and other government and regulatory
          authorities;
        </MediumText>
      </Unorderedlist>
      <Unorderedlist>
        <MediumText>
          to third parties such as service providers who Sipora uses to provide
          functionality to its apps. In particular, your online banking username
          and password will be collected and stored by service providers in
          order for details of your spending transactions to be obtained from
          your bank’s online banking platform and to perform funds transfers of
          round ups;
        </MediumText>
      </Unorderedlist>
      <Unorderedlist>
        <MediumText>
          to third parties who assist Sipora to manage or
          develop Sipora’s business; or
        </MediumText>
      </Unorderedlist>
      <Unorderedlist>
        <MediumText>
          for the purposes of facilitating or implementing a transfer/sale of
          all or part of Sipora’s assets or business
        </MediumText>
      </Unorderedlist>

      <MediumText>
        Your information may be disclosed to third parties outside of Australia.
      </MediumText>

      <LargeText style={t.fontBold}>Cookies</LargeText>

      <MediumText>
        Sipora’s website or apps may employ the use of cookies in order to
        monitor the usage levels relating to Sipora’s website or apps and to
        monitor which features of Sipora’s website or apps are most frequently
        accessed. Using cookies allows Sipora to constantly enhance its website
        and apps in order to make them more user-friendly.
      </MediumText>
      <MediumText>
        Most web browser software allows you to block cookies if you choose to
        do so.
      </MediumText>

      <LargeText style={t.fontBold}>
        Your email Address and the Spam Act
      </LargeText>

      <MediumText>
        Sipora will store your email address if you send Sipora a query online
        or email. Storage of your email address allows Sipora to advise you of
        any upgrades or enhancements to Sipora’s current services as well as any
        new products or services Sipora offers or information which Sipora
        thinks may be relevant to you.
      </MediumText>
      <MediumText>
        Sipora is fully compliant with the 
        <MediumText style={t.italic}>Spam Act</MediumText> 2003 (Cth) and Sipora
        will not send you promotional material if you do not want it sent to
        you. Sipora will not forward your email address to any third parties
        without your express permission.
      </MediumText>

      <LargeText style={t.fontBold}>
        How to access or correct your personal information or make a privacy
        complaint
      </LargeText>

      <MediumText>
        If you wish to access any of your personal information that Sipora holds
        or would like to correct any errors in that information, please
        contact Sipora using the contact details set out at{' '}
        <LinkText onPress={() => Linking.openURL('http://www.sipora.io')}>
          www.sipora.io
        </LinkText>
        .
      </MediumText>
      <MediumText>
        You may also use these contact details to notify Sipora of any privacy
        complaint you have against Sipora, including if you think that Sipora
        has failed to comply with the Australian Privacy Principles (APP)
      </MediumText>
      <MediumText>
        Sipora will investigate any complaints received in writing and endeavour
        to respond to your complaint within 14 days of receipt of the complaint.
      </MediumText>
      <MediumText>
        While Sipora will use its best endeavours to address any complaint that
        you may have, if you are unsatisfied in the manner in which Sipora deals
        with your complaint, you may also be able to lodge a complaint with a
        relevant regulator such as the Australian Information Commissioner (
        <LinkText onPress={() => Linking.openURL('https://www.oaic.gov.au')}>
          www.oaic.gov.au
        </LinkText>
        ).
      </MediumText>

      <LargeText style={t.fontBold}>About this Statement</LargeText>

      <MediumText>
        This Statement has been issued by Sipora, effective as at 01 July 2021.
        From time to time, Sipora may need to change this Statement.
      </MediumText>
      <MediumText>
        Sipora will post the updated version at{' '}
        <LinkText onPress={() => Linking.openURL('http://www.sipora.io')}>
          www.sipora.io
        </LinkText>{' '}
        and it will also be available on the App. The updated version will apply
        to all of your information held by Sipora at the time.
      </MediumText>
    </>
  );
};
