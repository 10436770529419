import React, { FunctionComponent, useEffect, useState } from "react";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { StatusBar } from "expo-status-bar";
import AsyncStorage from "@react-native-async-storage/async-storage";
import moment from "moment";

import useCachedResources from "../hooks/useCachedResources";

import { Spinner } from "../components/organisms/Spinner";
import { getUserToken } from "./configs";
import { useGetUserQuery, useGetAccountQuery } from "./slices";
import { AppContainer } from "../components/layout/AppContainer";
import { appConfig } from "../config/config";
import { RootNavigation } from "./RootNavigation";
import { DASHBOARD_GRANTED_TIME } from "../constants/Common";

type RootScreenProps = {};
export const RootScreen: FunctionComponent<RootScreenProps> = () => {
  const [isAuthenticated, setAuthenticated] = useState<boolean | null>(null);
  const [isDashboardBypassed, setDashboardBypassed] = useState<boolean | null>(
    null
  );

  const initiateToken = async () => {
    const token = await getUserToken();

    setAuthenticated(token !== null);
  };

  const checkDashboardBypassSession = async () => {
    try {
      const value = await AsyncStorage.getItem(DASHBOARD_GRANTED_TIME);
      const timestampString = value !== null ? value : "";

      const hours = moment().diff(moment(timestampString), "hours");
      setDashboardBypassed(hours <= 1);
    } catch (e) {
      setDashboardBypassed(false);
    }
  };

  useEffect(() => {
    initiateToken();
    checkDashboardBypassSession();
  }, []);

  const { data: userData, isLoading: isUserLoading } = useGetUserQuery(
    undefined,
    {
      skip: !isAuthenticated,
    }
  );

  const isLoadingCacheComplete = useCachedResources();

  if (
    isAuthenticated === null ||
    isDashboardBypassed === null ||
    !isLoadingCacheComplete ||
    isUserLoading
  ) {
    return <Spinner />;
  }

  return (
    <SafeAreaProvider>
      <AppContainer>
        <StatusBar backgroundColor={appConfig.primaryColour} />
        <RootNavigation
          isAuthenticated={isAuthenticated}
          isDashboardBypassed={isDashboardBypassed}
          user={userData}
        />
      </AppContainer>
    </SafeAreaProvider>
  );
};
