import React, { FunctionComponent } from "react";
import {
  createStackNavigator,
  StackScreenProps,
} from "@react-navigation/stack";

import { WelcomeRootScreen } from "../../components/screens/Onboarding/Welcome/WelcomeRootScreen";

const BasiqConsentStackRoot =
  createStackNavigator<BasiqConsentRootStackParamList>();

export const BasiqConsentStack: FunctionComponent<
  StackScreenProps<RootStackParamList, "BasiqConsent">
> = ({ route }) => {
  const user = route.params;

  return (
    <BasiqConsentStackRoot.Navigator
      initialRouteName="Welcome"
      screenOptions={{ headerShown: false }}
    >
      <BasiqConsentStackRoot.Screen
        name={"Welcome"}
        component={WelcomeRootScreen}
        options={{ title: "Welcome" }}
        initialParams={{
          user: JSON.stringify(user),
        }}
      />
    </BasiqConsentStackRoot.Navigator>
  );
};
