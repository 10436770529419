import { useQuery, UseQueryResult } from "react-query";
import { Payment } from "../../dtos/payment";
import { ApiError } from "../../services/api/api";
import { QueryKey } from "../../services/api/query";
import { getPayments } from "../../services/paymentService";

export const usePaymentsQuery = (): UseQueryResult<Payment[], ApiError> => {
  return useQuery<Payment[], ApiError>(QueryKey.Payments, () => getPayments(), {
    refetchInterval: 60000,
  });
};
