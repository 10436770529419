import { configureStore } from "@reduxjs/toolkit";
// Slices
import { baseApi } from "../slices";

export default configureStore({
  reducer: {
    [baseApi.reducerPath]: baseApi.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(baseApi.middleware),
});
