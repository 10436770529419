import React from "react";
import {
  Control,
  Controller,
  DeepMap,
  FieldError,
  FieldValues,
  SubmitHandler,
} from "react-hook-form";
import Button from "../../../atoms/Button";
import Input from "../../../molecules/Input";
import { ResetPasswordFormData } from "./ForgotPasswordScreen";

type Props = {
  onSubmit: (data: ResetPasswordFormData) => void;
  control: Control<ResetPasswordFormData>;
  errors: DeepMap<ResetPasswordFormData, FieldError>;
  handleSubmit: <
    TSubmitFieldValues extends FieldValues = ResetPasswordFormData,
  >(
    onValid: SubmitHandler<TSubmitFieldValues>,
  ) => (e?: any) => Promise<void>;
};

export const ResetPasswordForm = ({
  onSubmit,
  handleSubmit,
  control,
  errors,
}: Props): React.ReactElement => {
  return (
    <>
      <Controller
        name="email"
        control={control}
        render={({ onChange, value }) => (
          <Input
            error={errors.email ? "This is required." : undefined}
            onChangeText={(text: string) => onChange(text)}
            value={value}
            placeholder="Enter your email address..."
          />
        )}
        rules={{
          required: true,
        }}
      />

      <Button onPress={handleSubmit(onSubmit)} label="Reset Password" />
    </>
  );
};
