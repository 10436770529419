/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import React, { useEffect, useState } from "react";
import { Text, Animated, View, Platform } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { t } from "react-native-tailwindcss";
import { useForm } from "react-hook-form";
import {
  NavigationProp,
  RouteProp,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import { useToast } from "react-native-toast-notifications";
import Constants from "expo-constants";
import { FirebaseError } from "firebase/app";
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import ClientLogo from "../../../atoms/ClientLogo";
import { PersonalInfoForm } from "./PersonalInfoForm";
import { AccountInfoForm } from "./AccountInfoForm";
import { authStyles } from "../shared";
import { AuthFooter } from "../../../organisms/AuthFooter";
import { AuthScreensParamList } from "../../../../navigation/auth-screens";
import { firebaseApp } from "../../../../config/firebase";
import {
  createUser,
  CreateUserRequest,
} from "../../../../services/userService";
import { HCenterStack } from "../../../layout/HStack";
import { ScreenContainer } from "../../../layout/ScreenContainer";
import { Spinner } from "../../../organisms/Spinner";
import { FormStage } from "./registration-form-stage";
import { appConfig } from "../../../../config/config";
import { ExtraLargeText } from "../../../atoms/ExtraLargeText";
import { MediumText } from "../../../atoms/MediumText";
import {
  ApiError,
  convertErrorCodeToErrorMessage,
  getErrorMessage,
} from "../../../../services/api/api";

export type PersonalInfoFormData = {
  firstName: string;
  lastName: string;
  country: string;
  mobile: string;
  mobilePrefix: string;
};

export type AccountInfoFormData = {
  firstName: string;
  lastName: string;
  country: string;
  mobile: string;
  mobilePrefix: string;
  email: string;
  password: string;
  confirmPassword: string;
  contributionLimit?: number;
  customerReference?: string;
};

export type CustomErrors = {
  mobilePatternMatch?: boolean;
  passwordMatch?: boolean;
  termsAgree?: boolean;
  contributionLimit?: boolean;
  customerReference?: boolean;
  general?: string;
};

const decodeParams = (params: Record<string, string>) => {
  for (const key in params) {
    // eslint-disable-next-line no-param-reassign
    params[key] = decodeURIComponent(params[key]);
  }
  return params;
};

export const RegistrationScreen = (): React.ReactElement => {
  const toast = useToast();
  const navigation = useNavigation<NavigationProp<AuthScreensParamList>>();
  const params: AuthScreensParamList["Registration"] = decodeParams(
    useRoute<RouteProp<AuthScreensParamList, "Registration">>().params ?? {}
  );

  const [loading, setLoading] = useState(false);
  const [formStage, setFormStage] = useState(FormStage.Account);
  const [customErrors, setCustomErrors] = useState<CustomErrors>({});
  const [termsAgree, setTermsAgree] = useState(false);

  // Form stage transition animation
  const [fadeAnim, setFadeAnim] = useState(new Animated.Value(1));
  useEffect(() => {
    // on changes to formAnim value, animate it back to full opacity
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 500,
      useNativeDriver: false,
    }).start();
  }, [fadeAnim]);
  useEffect(() => {
    // trigger form animation on form stage change
    setFadeAnim(new Animated.Value(0.1));
  }, [formStage]);

  // Form stage one
  const {
    handleSubmit: handleSubmitOne,
    control: controlOne,
    formState: { errors: errorsOne },
  } = useForm<PersonalInfoFormData>({
    defaultValues: {
      firstName: params.firstName ?? "",
      lastName: params.lastName ?? "",
      country: "AU",
      mobile: params.mobile ?? "",
    },
    // this unregisters the form from resetting default values on submit
    shouldUnregister: false,
  });

  // Form stage two
  const {
    handleSubmit: handleSubmitTwo,
    control: controlTwo,
    formState: { errors: errorsTwo },
  } = useForm<AccountInfoFormData>({
    defaultValues: {
      email: params.email ?? "",
      password: "",
      confirmPassword: "",
      contributionLimit: Number(params.contributionLimit),
      customerReference: params.customerReference,
      firstName: params.firstName ?? "",
      lastName: params.lastName ?? "",
      country: "AU",
      mobile: params.mobile ?? "",
    },
    // this unregisters the form from resetting default values on submit
    shouldUnregister: false,
  });

  const registerUser = async (data: AccountInfoFormData): Promise<void> => {
    setLoading(true);

    try {
      const auth = getAuth(firebaseApp);
      const { user } = await createUserWithEmailAndPassword(
        auth,
        data.email,
        data.password
      );

      // todo fix case where it breaks between here. user gets stranded
      // need some way to roll back firebase change

      const payload: CreateUserRequest = {
        firstName: data.firstName,
        lastName: data.lastName,
        country: data.country,
        mobile: data.mobile,
        // mobile: personalInfoData.mobile,
      };
      console.log("payload", payload);
      if (data.contributionLimit) {
        payload.contributionLimit = data.contributionLimit;
      }

      if (data.customerReference) {
        payload.customerReference = data.customerReference;
      }

      await createUser(payload);

      const actionCodeSettings = {
        url: appConfig.tenantDomain,
        handleCodeInApp: false,
      };
      await sendEmailVerification(user, actionCodeSettings);
      toast.show("We just need to verify your email! Please check your inbox", {
        type: "success",
        duration: 3000,
      });
      navigation.navigate("VerifyEmail");

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    } catch (error: ApiError & Error & FirebaseError) {
      if (error.name && error.code && error.name === FirebaseError.name) {
        setCustomErrors({
          general: convertErrorCodeToErrorMessage(error.code),
        });
        return;
      }

      setCustomErrors({
        general: getErrorMessage(error),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <ScrollView>
      <ScreenContainer center>
        {/* if signup requires the web app, hide reg form on mobile */}
        {appConfig.features.signupRequiresWebApp && Platform.OS !== "web" ? (
          <>
            <HCenterStack>
              <ExtraLargeText>Unavailable.</ExtraLargeText>
            </HCenterStack>
            <MediumText>
              Registration is only available via a referral link. If you have
              lost your referral link then please contact{" "}
              {Constants.manifest?.extra?.company
                ? `${Constants.manifest?.extra?.company} `
                : ""}
              support.
            </MediumText>
            <AuthFooter
              prompts={[
                {
                  promptText: "Already have an account?",
                  promptLink: "Login",
                  promptLinkText: "Log In",
                },
              ]}
            />
          </>
        ) : (
          <KeyboardAwareScrollView
            keyboardShouldPersistTaps="always"
            scrollEnabled
            resetScrollToCoords={{ x: 0, y: 0 }}
            contentContainerStyle={[authStyles.scrollView, { paddingHorizontal: 30 }]}
            extraScrollHeight={60}
          >
            {loading ? (
              <Spinner />
            ) : (
              <>
                <HCenterStack style={authStyles.imageContainer}>
                  <View style={{ width: "70%", height: "100%" }}>
                    <ClientLogo />
                  </View>
                </HCenterStack>

                <Animated.View
                  style={{
                    opacity: fadeAnim, // Bind opacity to animated value
                  }}
                >
                  <AccountInfoForm
                    onSubmit={registerUser}
                    customErrors={customErrors}
                    setCustomErrors={setCustomErrors}
                    setFormStage={setFormStage}
                    handleSubmit={handleSubmitTwo}
                    control={controlTwo}
                    errors={errorsTwo}
                    termsAgree={termsAgree}
                    setTermsAgree={setTermsAgree}
                  />
                </Animated.View>

                {customErrors.general && (
                  <Text style={[authStyles.error, t.mT2]}>
                    {customErrors.general}
                  </Text>
                )}

                <AuthFooter
                  prompts={[
                    {
                      promptText: "Already have an account?",
                      promptLink: "Login",
                      promptLinkText: "Log In",
                    },
                  ]}
                />
              </>
            )}
          </KeyboardAwareScrollView>
        )}
      </ScreenContainer>
    </ScrollView>
  );
};
