import { t } from "react-native-tailwindcss";

export const authStyles = {
  scrollView: [t.flex1, t.justifyCenter, t.pX1],
  imageContainer: [{ height: 180 }, t.mB2],
  error: [t._mT1, t.mB2, t.textRed500],
  coloredLink: [{ color: '#FFC829' }],
  loginLink: [t.textCenter, t.mT2],
  footer: [t.mT20],
  footerText: [t.textCenter, t.mT2],
};
