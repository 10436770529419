import React, { FunctionComponent } from "react";
import type { StackScreenProps } from "@react-navigation/stack";

import { BasiqJobStatusFailure } from "./actions/BasiqJobStatusFailure";
import { User } from "../../../../dtos/user";
import { BasiqJobStatusSuccess } from "./actions/BasiqJobStatusSuccess";
import { OnboardCompletedFailure } from "./actions/OnboardCompletedFailure";

type WelcomeRootScreenProps = {} & StackScreenProps<
  BasiqConsentRootStackParamList,
  "Welcome"
>;

export const WelcomeRootScreen: FunctionComponent<WelcomeRootScreenProps> = ({
  route,
}): React.ReactElement => {
  const { user } = route.params;
  const { basiqJobStatus, onboardingComplete } = JSON.parse(user) as User;

  const jobStatusList = basiqJobStatus ?? [];

  const failedJobs: IBasiqJobStatus[] = jobStatusList.filter(
    ({ status }) => status === "failed"
  );

  if (onboardingComplete) {
    return <OnboardCompletedFailure />;
  } else {
    if (jobStatusList.length === 0) {
      return <BasiqJobStatusSuccess />;
    }

    if (failedJobs.length) {
      return <BasiqJobStatusFailure />;
    }
  }

  return <></>;
};
