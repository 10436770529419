import { appConfig } from "../config/config";
import api from "./api/api";

export type Tenant = {
  name: string,
  emailAddress: string,
  website?: string,
  _id?: string,
  tenantDomain?: string
};

export const getTenantDetails = async () => {
  return api.get<Tenant>(
    `common/${appConfig.tenant}/tenant`
  );
};