import { QueryClient } from "react-query";
import { StatusCodes } from "http-status-codes";
import { ApiError, isAxiosError } from "./api";

const RETRY_COUNT = 3;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      /* Note: failureCount does not include the current failure */
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        // Don't retry 400s - we'll just get the same validation errors again!
        // Don't retry 401s - we'll just get the same authentication error again!
        if (
          isAxiosError(error) &&
          ((error as ApiError).response?.status === StatusCodes.BAD_REQUEST ||
            (error as ApiError).response?.status === StatusCodes.UNAUTHORIZED)
        ) {
          return false;
        }

        return failureCount + 1 < RETRY_COUNT;
      },
    },
  },
});

export enum QueryKey {
  User = "user",
  Accounts = "accounts",
  Institutions = "institutions",
  Payments = "payments",
  Jobs = "jobs",
  Club = "club",
  CreateBasiqBankJob = "CreateBasiqBankJob",
  CreateReconsentBasiqBankJob = "CreateReconsentBasiqBankJob",
  BasiqBankAccountSync = "BasiqBankAccountSync",
  SyncBasiqBankAccountsSync = "SyncBasiqBankAccountsSync",
  LinkedSavingAccount = "LinkedSavingAccount",
  UpdateIslinkedSavingsAccEnabled = "updateIslinkedSavingsAccEnabled",
  UpdateWeeklyMaximumLimit = "updateWeeklyMaximumLimit",
  // Connection related
  UpdateConsent = "UpdateConsent",
  RefreshBasiqConnection = "RefreshBasiqConnection",
  Tenant = "tenant",
}
