import React from "react";
import { NavigationProp } from "@react-navigation/native";
import { HeaderBackButton } from "@react-navigation/stack";
import { ForgotPasswordScreen } from "../components/screens/Auth/ForgotPassword/ForgotPasswordScreen";
import { LoginScreen } from "../components/screens/Auth/Login/LoginScreen";
import { RegistrationScreen } from "../components/screens/Auth/Registration/RegistrationScreen";
import { HelpScreen } from "../components/screens/Shared/Help/HelpScreen";
import { PrivacyPolicyScreen } from "../components/screens/Shared/PrivacyPolicy/PrivacyPolicyScreen";
import { TermsScreen } from "../components/screens/Shared/Terms/TermsScreen";
import { NavigationScreen, Options } from "./shared";

export type AuthScreensParamList = {
  Login: undefined;
  Registration: {
    customerReference?: string;
    contributionLimit?: string;
    firstName?: string;
    lastName?: string;
    mobile?: string;
    email?: string;
  };
  ForgotPassword: undefined;
  Terms: undefined;
  PrivacyPolicy: undefined;
  Help: undefined;
  VerifyEmail: undefined;
};

interface AuthNavigationScreen extends NavigationScreen {
  name: keyof AuthScreensParamList;
}

export const authScreens: AuthNavigationScreen[] = [
  {
    name: "Login",
    component: LoginScreen,
    options: ({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      navigation,
    }: {
      navigation: NavigationProp<AuthScreensParamList>;
    }) => ({ headerShown: false }),
  },
  {
    name: "Registration",
    component: RegistrationScreen,
    options: ({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      navigation,
    }: {
      navigation: NavigationProp<AuthScreensParamList>;
    }) => ({ headerShown: false }),
  },
  {
    name: "ForgotPassword",
    component: ForgotPasswordScreen,
    options: ({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      navigation,
    }: {
      navigation: NavigationProp<AuthScreensParamList>;
    }) => ({ headerShown: false }),
  },
  {
    name: "Terms",
    component: TermsScreen,
    options: ({
      navigation,
    }: {
      navigation: NavigationProp<AuthScreensParamList>;
    }): Options => ({
      title: "T&C's",
      headerShown: true,
      headerLeft: () => (
        <HeaderBackButton
          labelVisible={false}
          onPress={() => {
            navigation.goBack();
          }}
        />
      ),
    }),
  },
  {
    name: "PrivacyPolicy",
    component: PrivacyPolicyScreen,
    options: ({
      navigation,
    }: {
      navigation: NavigationProp<AuthScreensParamList>;
    }): Options => ({
      title: "Privacy Policy",
      headerShown: true,
      headerLeft: () => (
        <HeaderBackButton
          labelVisible={false}
          onPress={() => {
            navigation.goBack();
          }}
        />
      ),
    }),
  },
  {
    name: "Help",
    component: HelpScreen,
    options: ({
      navigation,
    }: {
      navigation: NavigationProp<AuthScreensParamList>;
    }): Options => ({
      headerShown: true,
      headerLeft: () => (
        <HeaderBackButton
          labelVisible={false}
          onPress={() => {
            navigation.goBack();
          }}
        />
      ),
    }),
  },
];
