import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { appConfig } from "../../config/config";
import { Job } from "../../dtos/job";

// Reusing the same user dto
import { User } from "../../dtos/user";
import { BankAccount } from "../../services/bankService";
import { getUserToken } from "../configs";

export const baseApi = createApi({
  reducerPath: "base",
  baseQuery: fetchBaseQuery({
    baseUrl: `${appConfig.baseUrl}/${appConfig.env}/api/`,
    prepareHeaders: async (headers) => {
      const bearerToken = await getUserToken();

      if (bearerToken) {
        headers.set("Authorization", `Bearer ${bearerToken}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getUser: builder.query<User, void>({
      query: () => `basiq-user/${appConfig.tenant}/user/get`,
    }),
    getAccount: builder.query<BankAccount[], void>({
      query: () => `bank/${appConfig.tenant}/banks/user/accounts`,
    }),
    createBasiqBankJob: builder.query<Job, void>({
      query: () => `basiq-bank/${appConfig.tenant}/banks/user/connection`,
    }),
    getBasiqJob: builder.query<Job, string>({
      query: (jobId) => `common/${appConfig.tenant}/jobs/${jobId}`,
    }),
  }),
});

export const {
  useGetUserQuery,
  useGetAccountQuery,
  useCreateBasiqBankJobQuery,
  useGetBasiqJobQuery,
} = baseApi;
