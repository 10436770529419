import React, { useState, useEffect } from "react";
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { AuthScreensParamList } from '../../../../navigation/auth-screens';
import { Controller, useForm } from "react-hook-form";
import { Text, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { t } from "react-native-tailwindcss";
import {
  getAuth,
  User as FirebaseUser,
  signInWithEmailAndPassword,
  sendEmailVerification
} from "firebase/auth";
import Button from "../../../atoms/Button";
import { CheckBox } from "react-native-elements";
import Input from "../../../molecules/Input";
import LoginInput from "../../../molecules/LoginInput";
import ClientLogo from "../../../atoms/ClientLogo";
import { firebaseApp } from "../../../../config/firebase";
import { AuthFooter } from "../../../organisms/AuthFooter";
import { authStyles } from "../shared";
import { HCenterStack } from "../../../layout/HStack";
import { ScreenContainer } from "../../../layout/ScreenContainer";
import { Spinner } from "../../../organisms/Spinner";
import { MediumText } from "../../../atoms/MediumText";
import emailIcon from "../../../../assets/images/emailIcon.png";
import passwordIcon from "../../../../assets/images/passwordIcon.png";
import { saveLoginData, retrieveLoginData } from "./LoginLocalStorageHandler"
import { ApiError } from "../../../../services/api/api";
import { FirebaseError } from "firebase/app";
import { appConfig } from "../../../../config/config";
import { useToast } from "react-native-toast-notifications";

type FormData = {
  email: string;
  password: string;
  isRememberMe: boolean;
};

type CustomErrors = {
  general?: string;
};

export const LoginScreen = (): React.ReactElement => {
  const [loading, setLoading] = useState(false);
  const [customErrors, setCustomErrors] = useState<CustomErrors>({});
  const [isRememberMeOn, setIsRememberMeOn] = useState<boolean>(false);
  const [firebaseUser, setFirbaseUser] = useState<FirebaseUser | null>(null);
  const navigation = useNavigation<NavigationProp<AuthScreensParamList>>();
  const toast = useToast();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: { email: "", password: "" },
    // note: this unregisters the form from resetting values on submit or route change
    // note... fix this never clearing when route changes with useFocusEffect()
    // on react-navigation and calling reset() on react-hook-form

    // alternatively. we could add the same animation to the form that the register form has,
    // as that seems to trigger a form reset when route changes due to it being
    // removed entirely from the dom at the beginning of the animation
    shouldUnregister: false,
  });

  useEffect(() => {
    const loginData: FormData = retrieveLoginData();
    if (loginData && loginData.isRememberMe) {
      setIsRememberMeOn(loginData.isRememberMe);
      control.setValue("email", loginData.email);
      control.setValue("password", loginData.password);
    }
  }, [])

  const onSubmit = async ({ email, password }: FormData) => {
    setCustomErrors({
      ...customErrors,
      general: undefined,
    });
    setLoading(true);

    try {
      const auth = getAuth(firebaseApp);
      const { user: firebaseUser } = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      // note: firebase auth state changing triggers a refetch on the user object in the authContext.tsx
      // once auth state is valid AND the user object is populated, app.tsx kicks in to redirect to the appropriate app screen
      setFirbaseUser(firebaseUser);
      handleSignInResult(firebaseUser);
    } catch (error: ApiError & Error & FirebaseError & any) {
      setFirbaseUser(null);
      console.log("error - ", JSON.stringify(error));

      setCustomErrors({
        ...customErrors,
        general:
          mapAuthCodeToMessage(error ?? "Something went wrong, please contact support"),
      });
    } finally {
      const loginData: FormData = {
        email: email,
        password: password,
        isRememberMe: isRememberMeOn
      }
      saveLoginData(loginData);
      setLoading(false);
    }
  };

  const mapAuthCodeToMessage = (authCode: any) => {
    if (authCode?.code) {
      switch (authCode?.code) {
        case "auth/invalid-password":
          return "Password provided is not corrected";

        case "auth/invalid-email":
          return "Provided email is invalid";

        case "auth/user-not-found":
          return "Provided email not found";

        case "auth/wrong-password":
          return "Password is invalid";

        case "auth/too-many-requests":
          return "Failed to send email due to too many requests, Please try again later"

        default:
          return authCode?.message;
      }
    } else {
      return authCode?.message;
    }
  }

  const handleSignInResult = (firebaseUser: FirebaseUser | null): void => {
    firebaseUser
      ? checkVerification(firebaseUser)
      : showError("Something is wrong with your account");
  };

  const showError = (message: string) => {
    setCustomErrors({
      ...customErrors,
      general: message,
    });
    setLoading(false);
  };

  const checkVerification = (firebaseUser: FirebaseUser): void => {
    if (!firebaseUser.emailVerified) {
      showError(
        "Your email address is not verified, please follow the link in the verification email we sent before trying again, or"
      );
    } else {
      window.location.reload();
    }
  };

  const resendVerificationEmail = async () => {
    setLoading(true);
    toast.hideAll();
    const actionCodeSettings = {
      url: appConfig.tenantDomain,
      handleCodeInApp: false,
    };
    if (firebaseUser) {

      try {
        await sendEmailVerification(firebaseUser, actionCodeSettings);
        toast.show("We just need to verify your email! Please check your inbox", {
          type: "success",
          duration: 3000,
        });
        navigation.navigate("VerifyEmail");
        setCustomErrors({});
      } catch (error: any) {
        toast.show(mapAuthCodeToMessage(error ?? "Failed to send email"), {
          type: "danger",
          duration: 4000,
        });

      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <ScrollView>
      <ScreenContainer center>
        <KeyboardAwareScrollView
          keyboardShouldPersistTaps="always"
          scrollEnabled={true}
          resetScrollToCoords={{ x: 0, y: 0 }}
          contentContainerStyle={[authStyles.scrollView, { paddingHorizontal: 20 }]}
          extraScrollHeight={60}
        >
          {loading ? (
            <Spinner />
          ) : (
            <>
              <HCenterStack style={[{ height: 250, paddingVertical: 20 }, t.mB2]}>
                <View style={{ height: "100%", width: "100%" }}>
                  <ClientLogo />
                </View>
              </HCenterStack>
              <View style={{ paddingBottom: 8, marginTop: 10, paddingHorizontal: 20 }}>
                <MediumText center style={{ fontSize: 23, fontWeight: '400' }}>
                  Let's Sign in
                </MediumText>
                <MediumText style={{ fontSize: 16 }} center>
                  Welcome to Ronald McDonald House Round-Up!
                </MediumText>
              </View>
              <View style={{ paddingBottom: 8 }}>
                <Controller
                  name="email"
                  control={control}
                  render={({ onChange, value }) => (
                    <LoginInput
                      error={errors.email ? "This is required." : undefined}
                      onChangeText={(text: string) => onChange(text)}
                      value={value}
                      placeholder="Email Address"
                      sourceIcon={emailIcon}
                    />
                  )}
                  rules={{
                    required: true,
                  }}
                />
              </View>
              <View style={{ paddingBottom: 3 }}>
                <Controller
                  name="password"
                  control={control}
                  render={({ onChange, value }) => (
                    <LoginInput
                      error={errors.password ? "This is required." : undefined}
                      onChangeText={(text: string) => onChange(text)}
                      value={value}
                      placeholder="Password"
                      secureTextEntry
                      sourceIcon={passwordIcon}
                    />
                  )}
                  rules={{
                    required: true,
                  }}
                />
              </View>
              <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingBottom: 5 }}>
                <CheckBox
                  title={
                    <Text>
                      Remember me
                    </Text>
                  }
                  checked={isRememberMeOn}
                  onPress={() => {
                    setIsRememberMeOn(!isRememberMeOn);
                  }}
                  containerStyle={{
                    borderWidth: 0,
                    padding: 0,
                    marginLeft: 0,
                  }}
                  textStyle={[t.pL1, t.fontNormal, t.mL2, t.mR1, t.textGray600]}
                  checkedColor="#FFC829"
                  uncheckedColor="#FFC829"
                />
                <Text
                  onPress={() => navigation.navigate("ForgotPassword")}
                  style={[t.mT2, { color: '#FFC829' }]}
                >
                  Forgot password ?
                </Text>
              </View>
              <Button onPress={handleSubmit(onSubmit)} label="Sign In" />

              {customErrors.general && (
                <Text style={[authStyles.error, t.mT2]}>
                  {customErrors.general}
                  {(firebaseUser && !firebaseUser.emailVerified) ?
                    <Text>
                      <Text style={[t.textBlue400, t.pX1]}
                        onPress={() => resendVerificationEmail()}
                      >Click here</Text>
                      <Text style={[authStyles.error]}>
                        to resend verification email
                      </Text>
                    </Text>
                    : null
                  }
                </Text>
              )}

              <AuthFooter
                prompts={[
                  {
                    promptText: "Don't have an account?",
                    promptLink: "Registration",
                    promptLinkText: "Sign Up",
                  },
                ]}
              />
            </>
          )}
        </KeyboardAwareScrollView>
      </ScreenContainer>
    </ScrollView >
  );
};
